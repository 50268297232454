import React from "react";
import "./Home.css"
import { useState } from "react";
import { useEffect } from "react";
import translations from "./../../translations.json"
import AppointmentManagement from "../../appointment-management/AppointmentManagement.js";
import { getRequestWithToken } from "../../http-requests/HTTPRequests.js";

/**
 * This function returns the Home.
 * It is the default page for the web-application.
 */
export default function Home() {
    const [files, setFiles] = useState([]);

    const currentTranslations = translations[sessionStorage.getItem('language')] || {};

    useEffect(() => {
        if(sessionStorage.getItem("user")){
            getFiles()
        }
    }, [])
    async function getFiles() {
            getRequestWithToken("", `/api/scheduling/filedata/displayfiledata/username?username=${sessionStorage.getItem("user")}`, sessionStorage.getItem("token"))
                .then(response => {
                    console.log(response.data + " repsonse data")
                    setFiles(response.data)
                }).catch(error => {
                    console.log(error);
                })  
        
    }

    function downloadFile(file, id) {
        getRequestWithToken("", `/api/scheduling/filedata/downloadfiledata/filedataid?filedataid=${id}`, sessionStorage.getItem("token"))
            .then(response => {
                const blob = new Blob([response.data], { type: file.fileDataType });
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = file.fileDataName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch(error => {
                console.log(error);
            })
    }

    return (
        <div className="center">
            <div>
                {sessionStorage.getItem('user') && <AppointmentManagement username={sessionStorage.getItem("user") } />}
            </div>
            <div>
                {sessionStorage.getItem('user') &&
                    <ul>
                        {files.map((file) => (
                            <li key={file.fileDataId}>
                                <span>{file.fileDataName}</span>
                                <button onClick={() => downloadFile(file, file.fileDataId)}>Download</button>
                            </li>
                        ))}
                    </ul>
                }
                
            </div>
        </div>
    )
}
