import './App.css';
import Login from './user-management/login/Login.js'
import Navbar from './components/navbar/Navbar.js';
import Registration from './user-management/registration/Registration.js'
import Home from './components/home/Home.js'
import AccountManagement from './user-management/account-management/AccountManagement.js'
import { Route, Routes } from "react-router-dom"
import { useEffect, useState } from 'react';
import ControlAnITA from './experiment-control/exp-control/anita/ControlAnITA.js';
import AdminFunctionalities from './admin-functionalities/Admin-Functionalities.js';
import Config from './components/config/Config.js';
import UserDescription from './admin-functionalities/user/user-description/UserDescription.js'
import ExpControl from './experiment-control/exp-control/Exp-Control.js';
import FacilitiesOverview from './components/exp-facilities-overview/overview/FacilitiesOverview.js';
import InstitutionsOverview from './admin-functionalities/institution/institution-overview/InstitutionsOverview.js';
import UserOverview from './admin-functionalities/user/user-overview/UserOverview.js';
import Requests from './admin-functionalities/request/Requests.js';
import DataProtection from './components/data-protection/DataProtection.js';
import FAQ from './components/faq/FAQ.js';
import Footer from './components/footer/Footer.js';
import InstitutionDescription from './admin-functionalities/institution/institution-description/InstitutionDescription.js';
import FacilitiesDescription from './components/exp-facilities-overview/description/FacilitiesDescription.js';

function App(props) {



  const [isLoggedIn, setIsLoggedin] = useState(false);

  let loginbytokenbool = false;

  /**
   * Tries to read the fontSize of the sessionStorage.
   * If the sessionStorage contains an item 'fontSize', it gets set as the current font-size.
   * Otherwise it gets set to 16 as the default value.
   */
  


  useEffect(() => {
    if (!sessionStorage.getItem("fontSize")) {
      sessionStorage.setItem("fontSize", "16")
    }
  }, [])

  const [fontSize, setFontSize] = useState(sessionStorage.getItem("fontSize"))

  useEffect(() => {
    if (!sessionStorage.getItem("language")) {
      sessionStorage.setItem("language", "en")
    }
  }, [])

  function handleConfigChange() {
    setFontSize(sessionStorage.getItem("fontSize"))
  }


  // //Handles the fontSizeChange called in the config.
  // function handleFontSizeChange(){
  //   const newFontSize = parseInt(event.target.value, 10);
  //   setFontSize(newFontSize)
  //   sessionStorage.setItem("fontSize", newFontSize.toString())
  // }


  //Handles the loginSuccess state. Is needed to display the correct Links in the Navbar after login.
  const handleLoginSuccess = () => {
    setIsLoggedin(true);
  }

  /**
   * This method loggs the user out. It is implemented in the App-component, because it is used by different components. It sends a axios-request to the server. If the request was successful, it removes the details from the sessionStorage.
   */
  async function handleLogout() {
    sessionStorage.removeItem("user")
    sessionStorage.removeItem("role")
    sessionStorage.removeItem("token")
    sessionStorage.removeItem("userid")
    setIsLoggedin(false);
  }

  if (typeof props.token !== 'undefined' && isLoggedIn===false) {
    sessionStorage.setItem("user", props.user)
    sessionStorage.setItem("role", props.role)
    sessionStorage.setItem("token", props.token)
    sessionStorage.setItem("userid", props.userid)
    sessionStorage.setItem("facilities",props.facilities)
    setIsLoggedin(true);
  }
    return (
        <div className="App" style={{ fontSize: `${fontSize}px` }}>
          <Navbar isLoggedIn={isLoggedIn} onLogout={handleLogout} />
          <div className='global'>
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/login" element={<Login onLoginSuccess={handleLoginSuccess} />}></Route>
              <Route path="/registration" element={<Registration />} />
              <Route path="/facilities_overview" element={<FacilitiesOverview />} />
              <Route path="/facilities_overview/facility/:name/:id" element={<FacilitiesDescription />} />
              <Route path="/admin_functionalities" element={<AdminFunctionalities />} />
              <Route path="/admin_functionalities/institutions" element={<InstitutionsOverview  />} />
              <Route path="/admin_functionalities/user_management" element={<UserOverview />} />
              <Route path="/admin_functionalities/requests" element={<Requests />} />
              <Route path="/account_management" element={<AccountManagement changeAccountDetailsSuccess={handleLogout} />} />
              <Route path='/control_AnITA' element={<ControlAnITA />}></Route>
              <Route path='/config' element={<Config handleChange={handleConfigChange} />}></Route>
              <Route path="/changeUserDetails/:id" element={<UserDescription/>}></Route>
              <Route path="/changeInstitutionDetails/:id" element={<InstitutionDescription changeInstitutionSuccess={handleLogout} />}></Route>
              <Route path="/experiment/:facilityId/:appointmentId/:connectedFacilityName" element={<ExpControl />}></Route>
              <Route path="/dataprotection" element={<DataProtection />}></Route>
              <Route path="/faq" element={<FAQ />}></Route>
            </Routes>
          </div>
          <Footer></Footer>
        </div>
    );

}

export default App;
