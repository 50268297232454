import translations from "./../../translations.json"
import { useEffect, useState } from "react"
import { getRequestWithToken, postRequestWithToken } from "../../http-requests/HTTPRequests.js";


export default function Requests() {

    const currentTranslations = translations[sessionStorage.getItem('language')] || {};


    const [requests, setRequests] = useState([]);
    const registerRequests = requests.filter(request => request.userRequestName === "Register");
    const facilityRequests = requests.filter(request => request.userRequestName === "Facilities")


    useEffect(() => {
        getRequests();
    }, [])

    async function getRequests() {
        getRequestWithToken("", "/api/management/requests", sessionStorage.getItem("token"))
        .then(response => {
            console.log(response.data)
            setRequests(response.data)
        }).catch(error => {
            console.log(error)
        })
    }

    async function acceptRegister(requestName, username, usermail, id) {
        document.getElementById("requestsLabel").innerHTML = currentTranslations.adminAcceptingRequest
        postRequestWithToken("", "/api/management/requests/registration/accept", {
            userRequestName: requestName, userRequestValue: "STUDENT", username: username, email: usermail
        }, sessionStorage.getItem("token")).then(() => {
            setRequests(requests.filter((request) => request.userRequestId !== id));
            document.getElementById("requestsLabel").innerHTML = currentTranslations.adminAcceptedRequest
        }).catch(error => {
            document.getElementById("requestsLabel").innerHTML = currentTranslations.adminAcceptedRequestFailed
            console.log(error)
        })
    }

    async function declineRegister(requestName, username, usermail, id) {
        document.getElementById("requestsLabel").innerHTML = currentTranslations.adminDecliningRequest
        postRequestWithToken("", "/api/management/requests/registration/decline", {
            userRequestName: requestName, username: username, email: usermail
        }, sessionStorage.getItem("token"))
        .then(() => {
            setRequests(requests.filter((request) => request.userRequestId !== id));
            document.getElementById("requestsLabel").innerHTML = currentTranslations.adminDeclinedRequest
        }).catch(error => {
            document.getElementById("requestsLabel").innerHTML = currentTranslations.adminDeclinedRequestFailed
            console.log(error)
        })
    }

    async function acceptFacilityRequest(requestName, requestValue, username, usermail, id) {
        document.getElementById("requestsLabel").innerHTML = currentTranslations.adminAcceptingRequest
        postRequestWithToken("", "/api/management/requests/facilities/accept", {
            userRequestName: requestName, userRequestValue: requestValue , username: username, email: usermail
        }, sessionStorage.getItem("token")).then(() => {
            setRequests(requests.filter((request) => request.userRequestId !== id));
            document.getElementById("requestsLabel").innerHTML =  currentTranslations.adminAcceptedRequest
            console.log(sessionStorage.getItem("facilities"))
        }).catch(error => {
            document.getElementById("requestsLabel").innerHTML = currentTranslations.adminAcceptedRequestFailed
            console.log(error)
        })
    }

    async function declineFacilityRequest(requestName, username, id) {
        document.getElementById("requestsLabel").innerHTML = currentTranslations.adminDeclinedRequest
        postRequestWithToken("", "/api/management/requests/facilities/decline", {
            userRequestName: requestName, username: username
        }, sessionStorage.getItem("token"))
        .then(() => {
            setRequests(requests.filter((request) => request.userRequestId !== id));
            document.getElementById("requestsLabel").innerHTML = currentTranslations.adminDeclinedRequest
        }).catch(error => {
            document.getElementById("requestsLabel").innerHTML = currentTranslations.adminDeclinedRequestFailed
            console.log(error)
        })
    }

    if (sessionStorage.getItem("user")) {
    return (
        <>
            {(sessionStorage.getItem('role') === 'ADMIN' || sessionStorage.getItem('role') === 'ROOT_ADMIN' )&&
            
                <div className="center">
                    <p>{currentTranslations.adminRequestsRegister}</p>
                    {registerRequests.length > 0 ? (
                        <table className="admintablerequests">
                            <thead>
                                <tr className="settradmin">
                                    <td className="settabledataadmin name">{currentTranslations.adminFuncRequests}</td>
                                    <td className="settabledataadmin name">{currentTranslations.username}</td>
                                    <td className="settabledataadmin name">{currentTranslations.accept}</td>
                                    <td className="settabledataadmin name">{currentTranslations.decline}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {registerRequests.map(request => (
                                    <tr key={request.userRequestId} className="settradmin">
                                        <td className="settabledataadmin">{request.userRequestName}</td>
                                        <td className="settabledataadmin">{request.userName}</td>
                                        <td className="settabledataadmin">
                                            <button onClick={() => acceptRegister(request.userRequestName, request.userName, request.userName, request.userRequestId)}>{currentTranslations.accept}</button>
                                        </td>
                                        <td className="settabledataadmin">
                                            <button onClick={() => declineRegister(request.userRequestName, request.userName, request.userName, request.userRequestId)}>{currentTranslations.decline}</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p>{currentTranslations.noRegisterRequests}</p>
                    )}
                    <p>{currentTranslations.adminRequestsFacilities}</p>
                    {facilityRequests.length > 0 ? (
                        <table>
                            <thead>
                                <tr>
                                    <th>{currentTranslations.adminFuncRequests}</th>
                                    <th>{currentTranslations.username }</th>
                                    <th>{currentTranslations.accept}</th>
                                    <th>{currentTranslations.decline}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {facilityRequests.map(request => (
                                    <tr key={request.userRequestId}>
                                        <td>{request.userRequestName}</td>
                                        <td>{request.userName}</td>
                                        <td>
                                            <button onClick={() => acceptFacilityRequest(request.userRequestName, request.userRequestValue, request.userName, request.userName, request.userRequestId)}>{currentTranslations.accept}</button>
                                        </td>
                                        <td>
                                            <button onClick={() => declineFacilityRequest(request.userRequestName, request.userName, request.userRequestId)}>{currentTranslations.decline}</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                            <p>{currentTranslations.noFacilityRequests}</p>
                    )}
                    <label id="requestsLabel"></label>

                </div>}
        </>
        );
    }
    else {
        return (
            <div>{currentTranslations.loginRequired}</div>
        )
    }
}