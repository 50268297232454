import axios from 'axios'
import https from 'https-browserify';

let ecs = "https://tc2-rea.uol.de/access-control"
let ecs_var = ""

const instance = axios.create({
    httpsAgent: new https.Agent({  
      rejectUnauthorized: false
    })
});

export async function getRequestWithoutToken(domain, url) {
    if (domain !== "") {
        ecs_var = domain
    }
    else{
        ecs_var = ecs;
    }
    try {
        const response = await instance.get(ecs_var + url,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        );
        return response
    } catch (error) {
        throw error;
    }
}

export async function deleteRequestWithToken(domain, url, token) {
    if (domain !== "") {
        ecs_var = domain
    }
    else{
        ecs_var = ecs;
    }
    try {
        const response = await instance.delete(
            ecs_var + url, {
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + token
            }
        }
        );
        return response
    } catch (error) {
        throw error;
    }
}

export async function getRequestWithToken(domain, url, token) {
    if (domain !== "") {
        ecs_var = domain
    }
    else{
        ecs_var = ecs;
    }
    try {
        const response = await instance.get(ecs_var + url,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            }
        );
        console.log("Hier kommst hin")
        return response
    } catch (error) {
        console.log("Hier bei error123")
        throw error;
    }
}


//Request without Authorization for registration
export async function postRequestWithoutAuth(domain, url, jsonbody) {
    if (domain !== "") {
        ecs_var = domain
    }
    else{
        ecs_var = ecs;
    }
    try {
        const response = await instance.post(ecs_var + url,
            JSON.stringify(jsonbody),
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        return response
    } catch (error) {
        throw error;
    }
}

export async function postRequestWithToken(domain, url, jsonbody, token) {
    if (domain !== "") {
        ecs_var = domain
    }
    else{
        ecs_var = ecs;
    }
    try {
        const response = await instance.post(
            ecs_var + url,
            jsonbody,
            {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + token
                }
            }
        );
        return response
    } catch (error) {
        throw error;
    }
}


export async function putRequestWithToken(domain, url, jsonbody, token) {
    if (domain !== "") {
        ecs_var = domain
    }
    else{
        ecs_var = ecs;
    }
    try {
        const response = await instance.put(
            ecs_var + url,
            jsonbody,
            {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + token
                }
            }
        );
        return response
    } catch (error) {
        throw error;
    }
}

export async function getRequestWithCredentialsHeader(domain, url, jsonbody) {
    if (domain !== "") {
        ecs_var = domain
    }
    else{
        ecs_var = ecs;
    }
    try {
        const response = await instance.get(ecs_var + url,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(jsonbody.username + ":" + jsonbody.password)
                }, withCredentials: false
            }
        );
        console.log(response)
        return response
    } catch (error) {
        console.log("Hier bei error")
        throw error;

    }

}

export async function postRequestWithCredentialsHeader(domain, url, jsonbody) {
    if (domain !== "") {
        ecs_var = domain
    }
    else{
        ecs_var = ecs;
    }
    try {
        const response = await instance.post(
            ecs_var + url,
            JSON.stringify(jsonbody),
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa(jsonbody.username + ":" + jsonbody.password)
                }, withCredentials: false
            }
        );
        console.log("hier" + response)
        return response

    } catch (error) {
        console.log(error)
        throw error;
    }
}

