import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import translations from './../../translations.json';
import LanguageSelection from '../language/LanguageSelection.js';

export default function Navbar({ onLogout }) {
    const currentTranslations = translations[sessionStorage.getItem('language')] || {};

    const [active, setActive] = useState('nav-menu')
    const [toggleIcon, setToggleIcon] = useState("nav-toggler")
    //Sets the toggled-element to active, so it gets highlighted.
    function navToggle() {
        active === "nav-menu" ? setActive("nav-menu nav-active") : setActive("nav-menu")
        //TogglerIcon animation
        toggleIcon === "nav-toggler" ? setToggleIcon("nav-toggler toggle") : setToggleIcon("nav-toggler")
    }

    function CustomLink({ to, children, ...props }) {
        const closeNavbar = () => {
            setActive("nav-menu");
            setToggleIcon("nav-toggler");
            if (props.onClick) {
                props.onClick();
            }
        }
        return (
            <li className="nav-item">
                <Link to={to} {...props} onClick={closeNavbar}>{children}</Link>
            </li>
        )
    }


    return (<nav className="nav">
        <Link to="/" className="site-title">TC2</Link>
        <ul className={active}>
            <CustomLink to="/">Home</CustomLink>
            <CustomLink to="/facilities_overview">{currentTranslations.experimentFacilities}</CustomLink>
            {sessionStorage.getItem("user") ?
                <><CustomLink onClick={onLogout} to="/">Logout</CustomLink>
                    <CustomLink to="/account_management">{currentTranslations.accountManagement}</CustomLink>
                    {(sessionStorage.getItem("role") === "ROOT_ADMIN" || sessionStorage.getItem('role') === "ADMIN") && <CustomLink to="/admin_functionalities">{currentTranslations.adminFunctionalities}</CustomLink>}</> :
                <><CustomLink to="/login">Login</CustomLink>
                    <CustomLink to="/registration">{currentTranslations.buttonRegister}</CustomLink></>}
            <CustomLink to="/config">{currentTranslations.config}</CustomLink>
            <LanguageSelection></LanguageSelection>
        </ul>
        <div onClick={navToggle} className={toggleIcon}>
            <div className="line1">
            </div>
            <div className="line2">
            </div>
            <div className="line3"></div>
        </div>
    </nav>
    );
}
