import React, { useEffect, useState } from "react";
import './SimpleAnITA.css'
import GateValve from '../../exp-components/GateValve.js';
import PumpGeneral from "../../exp-components/PumpGeneral.js";
import Autoclave from "../../exp-components/Autoclave.js";
import Chart from '../charts/Chart.js';
import { SimpleAnITAMachine } from "./SimpleAnITAMachine.js"
import { useMachine } from "@xstate/react"
import translations from "../../../translations.json"
import HLSPlayer from "../livestream/Livestream.js";


/**
 * This function returns the ControlSimpleAnITA.
 * It is used to display control the SimpleAnITA.
 */
export default function ControlSimpleAnITA({ experimentState, handleSignal, signalMessage }) {
    let mode = "0";
    let actuators = "0";
    let glass_reactor = "0";
    let stirrer = "0";
    let eatank = "0";
    let light = "0";
    const [temperature, setTemperature] = useState(experimentState.messageParams.temperature)
    const [reactorLevel, setReactorLevel] = useState(experimentState.messageParams.reactorLevel)

    const [components, setComponents] = useState({
        vd: { isOpen: false, enabled: false },
        vw: { isOpen: false, enabled: false },
        vea: { isOpen: false, enabled: false },
        pea: { isOn: false, enabled: false },
        pw: { isOn: false, enabled: false },
        ac: { isOn: false, fillLevel: reactorLevel, enabled: false }
    });

    const [stSpeed, setStSpeed] = useState(0);
    const [changeStSpeed, setChangeStSpeed] = useState(0);

    const {modestate,actuatorsstate,glass_reactorstate,stirrerstate,eatankstate,lightstate, context } = setInitialState()
    const [state, send] = useMachine(() => SimpleAnITAMachine({modestate, actuatorsstate, glass_reactorstate, stirrerstate, eatankstate, lightstate}), {context: context})
    const [showDescription, setShowDescription] = useState(false);


    const currentTranslations = translations[sessionStorage.getItem("language")] || {}

    useEffect(() => {
        update();
    }, [reactorLevel])

    useEffect(() => {
        update();
    }, [stSpeed])

    useEffect(() => {
        //todo: remove debug console.log
        console.log(state);
        update();
    }, [state])

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        if (id === "stSpeed") {
            setChangeStSpeed(value);
        }
    }

    function setInitialState() {
        const stateString = experimentState.messageState.slice(1, -1);
        if (stateString.startsWith("6")) {
            const values = stateString.split("/");
            mode = values[0].slice(0, 1);
            actuators = values[0].slice(2);
            glass_reactor = values[1]
            stirrer = values[2].slice(0, 1);
            eatank = values[3]
            light = values[4]
        } else {
            [mode, eatank, light] = stateString.split('/')
        }

        let modestate, actuatorsstate, stirrerstate, glass_reactorstate, eatankstate, lightstate, contextGR, contextEA;

        switch (mode) {
            case "0":
                modestate = "Initialising"
                break;
            case "1":
                modestate = "Draining"
                break;
            case "2":
                modestate = "Idling"
                break;
            case "3":
                modestate = "Filling Tubes"
                break;
            case "4":
                modestate = "WT Filling"
                break;
            case "5":
                modestate = "After Fill Drain"
                break;
            case "6":
                modestate = "Experiment"
                break;
        }

        switch (actuators) {
            case "0":
                actuatorsstate = "pwoff peaoff vwclose veaclose vdclose"
                break;
            case "1":
                actuatorsstate = "pwoff peaoff vwclose veaclose vdopen"
                break;
            case "2":
                actuatorsstate = "pwoff peaoff vwclose veaopen vdclose"
                break;
            case "3":
                actuatorsstate = "pwoff peaon vwclose veaopen vdclose"
                break;
            case "4":
                actuatorsstate = "pwoff peaoff vwopen veaclose vdclose"
                break;
            case "5":
                actuatorsstate = "pwon peaoff vwopen veaclose vdclose"
                break;
            case "6":
                actuatorsstate = "pwoff peaoff vwopen veaopen vdclose"
                break;
            case "7":
                actuatorsstate = "pwon peaoff vwopen veaopen vdclose"
                break;
            case "8":
                actuatorsstate = "pwoff peaon vwopen veaopen vdclose"
                break;
        }

        switch (stirrer) {
            case "0":
                stirrerstate = "Stirrer Off"
                break;
            case "1":
                stirrerstate = "Stirrer On"
                break;
        }

        switch (glass_reactor) {
            case "0":
                glass_reactorstate = "Empty"
                break;
            case "1":
                glass_reactorstate = "Some Water"
                break;
            case "2":
                glass_reactorstate = "Enough Water"
                contextGR = "Enough Water"
                break;
            case "3":
                glass_reactorstate = "Water > 2xEA"
                contextGR = "Water > 2xEA"
                break;
            case "4":
                glass_reactorstate = "Water = 2xEA"
                break;
            case "5":
                glass_reactorstate = "Full"
                contextGR = "Full"
                break;
            case "6":
                glass_reactorstate = "Unknown"
                contextGR = "Unknown"
                break;
            case "7":
                glass_reactorstate = "Emptying"
                break;
        }

        switch (eatank) {
            case "0":
                eatankstate = "EA Empty"
                contextEA = "EA Empty"
                break;
            case "1":
                eatankstate = "EA Enough"
                contextEA = "EA Enough"
                break;
            case "2":
                eatankstate = "EA Full"
                contextEA = "EA Full"
                break;
        }

        switch (light) {
            case "0":
                lightstate = "Light Off"
                break;
            case "1":
                lightstate = "Light On"
        }

        const context = {
            contextEA: contextEA,
            contextGR: contextGR
        }
        return {
            modestate: modestate,
            actuatorsstate: actuatorsstate,
            glass_reactorstate: glass_reactorstate,
            stirrerstate: stirrerstate,
            eatankstate: eatankstate,
            lightstate: lightstate,
            context: context
        }
    }

    function update() {
        if(state.value["Mode"]["Experiment"]){
            const [pwState, peaState, vwState, veaState, vdState] = state.value["Mode"]["Experiment"]["Actuators"].split(' ');
            const stState = state.value["Mode"]["Experiment"]["Stirrer"];
            const eaState = state.value["Mode"]["Experiment"]["Glass Reactor"];
            setComponents({
                vd: { isOpen: vdState === 'vdopen', enabled: isEnabled("vd") },
                vea: { isOpen: veaState === 'veaopen', enabled: isEnabled("vea") },
                vw: { isOpen: vwState === 'vwopen', enabled: isEnabled("vw") },
                pea: { isOn: peaState === 'peaon', enabled: isEnabled("pea") },
                pw: { isOn: pwState === 'pwon', enabled: isEnabled("pw") },
                ac: { isOn: stState === 'Stirrer On', fillLevel: reactorLevel, enabled: isEnabled("st") }
            });
        }
        else{
            setComponents({
                vd: {isOpen: false, enabled: isEnabled("vd") },
                vw: { isOpen: false, enabled: isEnabled("vw") },
                vea: { isOpen: false, enabled: isEnabled("vea") },
                pea: { isOn: false, enabled: isEnabled("pea") },
                pw: { isOn: false, enabled: isEnabled("pw") },
                ac: { isOn: false, fillLevel: reactorLevel, enabled: isEnabled("st") }
            });
        }
    }

    function isEnabled(component) {
        const events = JSON.stringify(state.nextEvents);
        if (events.includes(component)) {
            return true;
        } else {
            return false;
        }
    }

    function handleCheckboxChange() {
        setShowDescription(!showDescription);
    }
    function sendSignal(signal, param) {
        handleSignal(
            signal, param
        )
    }

    function handleComponentIsOpenClick(componentKey) {
        document.getElementById("state").innerHTML = currentTranslations.controlAction
        const isOpen = !components[componentKey].isOpen;
        const signalState = `${componentKey}${isOpen ? "open" : "close"}`;
        if (state.nextEvents.includes(signalState)) {
            handleSignal(
                signalState, null
            )
        } else {
            document.getElementById("state").innerHTML = currentTranslations.controlActionFailed
        }
    }

    function handleAcClick() {
        document.getElementById("state").innerHTML = currentTranslations.controlAction
        const isOn = !components["ac"].isOn;
        const signalState = `st${isOn ? "on" : "off"}`;
        if (signalState === "stoff") {
            if (state.nextEvents.includes(signalState)) {
                handleSignal(
                    signalState, null
                )
            } else {
                document.getElementById("state").innerHTML = currentTranslations.controlActionFailed
            }
        } else {
            if (state.nextEvents.includes(signalState)) {
                handleSignal(
                    signalState, null//todo:readd `stirRPM: ${changeStSpeed}`
                )
            } else {
                document.getElementById("state").innerHTML = currentTranslations.controlActionFailed
            }
        }

    }

    function handleComponentIsOnClick(componentKey) {
        document.getElementById("state").innerHTML = currentTranslations.controlAction
        const isOn = !components[componentKey].isOn;
        const signalState = `${componentKey}${isOn ? "on" : "off"}`;
        if (state.nextEvents.includes(signalState)) {
            handleSignal(
                signalState, null
            )
        } else {
            document.getElementById("state").innerHTML = currentTranslations.controlActionFailed
        }
    }

    function handleComponentIsOnTextClick(componentKey) {
        document.getElementById("state").innerHTML = currentTranslations.controlAction
        let inputField = document.getElementById(`${componentKey}Input`);
        let inputValue = inputField.value;
        const signalState = `${componentKey}Amount`;
        handleSignal(
            signalState, `pumpAmount: ${inputValue}`
        )
    }


    useEffect(() => {
        if (signalMessage) {
            console.log("Received signal: ");
            if (signalMessage.signal.startsWith("FV::conf") || signalMessage.signal.startsWith("FV::valueupdate")) {
                console.log("Received signal: " + signalMessage.signal);
                const signalState = signalMessage.signal.slice(4)
                let signalString = signalState.toString();
                signalString = signalString.replace("conf","");
                //todo: remove debug console.log
                console.log(state);
                send(`${signalString}`);
                document.getElementById("state").innerHTML =
                    currentTranslations.controlActionSucc;
                const stateString = experimentState.messageState.slice(1, -1);
            }
            if(signalMessage.signal.startsWith("FV::confstoff")) {
                setStSpeed(0);
            }
            if (signalMessage.signal.startsWith("FV::valueupdate")) {
                if (signalMessage.parameters.temperature) {
                    setTemperature(signalMessage.parameters.temperature);
                }
                if (signalMessage.parameters.reactorLevel>-2) {
                    setReactorLevel(signalMessage.parameters.reactorLevel)
                    console.log("reactor level: "+ reactorLevel)
                }
                if (signalMessage.parameters.stirActualRPM) {
                    setStSpeed(signalMessage.parameters.stirActualRPM)
                }
            }
        }
    }, [signalMessage]);


//Noch irgendetwas hinzufügen, sodass nicht alle eingeloggten User es dürfen
    return (
        <>
            {sessionStorage.getItem('user') &&
                <div className="center">
                    <h1>SimpleAnITA</h1>
                    <div className="horizontalButtonsAnITA">
                        <div className="singleButtonAnITA">
                            <button onClick={() => sendSignal("eafill", null)} >Fill EA Tank</button>
                        </div>
                        <div className="singleButtonAnITA">
                            <button onClick={() => sendSignal("getready", null)} disabled={state.value["Mode"] !== "Idling" && state.value["EA Tank"] === "EA Full"}>{currentTranslations.getReadyAnITA}</button>
                        </div>
                        <div className="singleButtonAnITA">
                            <button onClick={() => sendSignal("backtoinit", null)}
                                    disabled={!state.nextEvents.includes('backtoinit')}>{currentTranslations.stopExperiment}</button>
                        </div>
                    </div>
                    <div>
                        <div>
                            <label>{currentTranslations.stirrerSpeedLabel}</label>
                            <input type="text" id="stSpeed" data-testid="stSpeed" value={stSpeed} readOnly="readOnly"/>
                        </div>
                        <label>{currentTranslations.stirrerSpeedInput}</label>
                        <input type="text" id="stSpeed" data-testid="stSpeed" onChange={(e) => handleInputChange(e)} />
                        <button onClick={() => handleAcClick()}>Stirrer on/off</button>
                    </div>
                    <div className={`horizontalAnITA`}>
                        <div className="center modelSimpleAnITA" disabled={state.value["Mode"] !== "Experiment"}>
                            <div className="component-container">
                                <p>Ac2O</p>
                                <svg
                                    version="1.1"
                                    width="95.998032"
                                    height="11.236069"
                                    viewBox="-0.5 -0.5 95.998032 11.236069"
                                    animation="{&quot;scriptArr&quot;:[],&quot;cellIdArr&quot;:[]}"
                                    isstartanimationwithpreviouspage="with"
                                    id="svg831"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="arrowAnITA1">
                                    <g
                                        id="g819"
                                        transform="translate(-40.499999,-200.38196)">
                                        <g
                                            shape-node=""
                                            id="g799">
                                            <path
                                                cell-id="dD5wv-8hnhxJDom6t97_-40"
                                                d="m 40,205.5 h 88.13"
                                                fill="none"
                                                stroke="#000000"
                                                strokeMiterlimit="10"
                                                pointerEvents="stroke"
                                                id="path795" />
                                            <path
                                                cell-id="dD5wv-8hnhxJDom6t97_-40"
                                                d="m 134.88,205.5 -9,4.5 2.25,-4.5 -2.25,-4.5 z"
                                                fill="#000000"
                                                stroke="#000000"
                                                strokeMiterlimit="10"
                                                pointerEvents="all"
                                                id="path797" />
                                        </g>
                                    </g>
                                </svg>
                                <div>
                                    <div className={`pumpAnITA 
                                    left ${components.pea.enabled}`} style={{width: "4vw", height: "4vh"}}>
                                        {components["pea"] && (
                                            <PumpGeneral isOn={components["pea"].isOn}
                                                         key={"pea"}
                                                         onClick={() => handleComponentIsOnClick("pea")}
                                                         className="left"
                                            />
                                        )}
                                    </div>
                                    <p className="pumpAnITAText">P1</p>
                                    <div className={`pumpAnITA 
                                    ${components.pea.enabled}`} style={{width: "4vw", height: "4vh"}}>
                                        {components["pea"] && (
                                            <input className="textinput" type="text" id="peaInput"
                                                   placeholder="in ml"></input>
                                        )}
                                        <button className="textinput"
                                                onClick={() => handleComponentIsOnTextClick("pea")}>Start P1
                                        </button>
                                    </div>
                                </div>
                                <svg
                                    version="1.1"
                                    width="66.343628"
                                    height="19.949997"
                                    viewBox="-0.5 -0.5 66.343628 19.949997"
                                    animation="{&quot;scriptArr&quot;:[],&quot;cellIdArr&quot;:[]}"
                                    isstartanimationwithpreviouspage="with"
                                    id="svg4997"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={`arrowAnITA2 ${components["pea"].isOn ? "on" : ""}`}>
                                    <g
                                        id="g4985"
                                        transform="translate(-172.495,-201.12051)">
                                        <g
                                            shape-node=""
                                            id="g4959">
                                            <path
                                                cell-id="dD5wv-8hnhxJDom6t97_-23"
                                                d="m 172,204.67 59.97,0.6"
                                                fill="none"
                                                stroke="#000000"
                                                strokeMiterlimit="10"
                                                pointerEvents="stroke"
                                                id="path4955" />
                                            <path
                                                cell-id="dD5wv-8hnhxJDom6t97_-23"
                                                d="m 237.22,205.32 -7.04,3.43 1.79,-3.48 -1.72,-3.52 z"
                                                fill="#000000"
                                                stroke="#000000"
                                                strokeMiterlimit="10"
                                                pointerEvents="all"
                                                id="path4957" />
                                        </g>
                                    </g>
                                </svg>
                                <div>
                                    <div className="valveAnITA false">
                                        {components["vea"] && (
                                            <GateValve
                                                isOpen={components["vea"].isOpen}
                                                key={"vea"}
                                                style={{ position: "absolute" }}
                                            />
                                        )}
                                    </div>
                                    <p className="valveAnITAText">V1</p>
                                </div>
                                <div>
                                    <svg
                                        version="1.1"
                                        width="87.368011"
                                        height="90.618042"
                                        viewBox="-0.5 -0.5 87.368011 90.618042"
                                        animation="{&quot;scriptArr&quot;:[],&quot;cellIdArr&quot;:[]}"
                                        isstartanimationwithpreviouspage="with"
                                        id="svg4997"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={`arrowAnITA3 ${components["pea"].isOn && components["vea"].isOpen ? "on" : ""}`}>
                                        <g
                                            id="g4985"
                                            transform="translate(-266.17001,-204)">
                                            <g
                                                shape-node=""
                                                id="g4953">
                                                <path
                                                    cell-id="dD5wv-8hnhxJDom6t97_-13"
                                                    d="m 265.67,204 h 33.66 q 10,0 10,10 v 65.5 q 0,10 10,10 h 27.34"
                                                    fill="none"
                                                    stroke="#000000"
                                                    strokeMiterlimit="10"
                                                    pointerEvents="stroke"
                                                    id="path4949" />
                                                <path
                                                    cell-id="dD5wv-8hnhxJDom6t97_-13"
                                                    d="m 351.92,289.5 -7,3.5 1.75,-3.5 -1.75,-3.5 z"
                                                    fill="#000000"
                                                    stroke="#000000"
                                                    strokeMiterlimit="10"
                                                    pointerEvents="all"
                                                    id="path4951" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="verikal">
                                    <div className="autclave">
                                        <p className="autocalveAnITAText">S01</p>
                                        {components["ac"] && (
                                            <Autoclave
                                                isOn={components["ac"].isOn}
                                                fillLevel={components["ac"].fillLevel}
                                                key={"ac"}
                                            />
                                        )}
                                    </div>

                                </div>
                                <div>
                                    <svg
                                        version="1.1"
                                        width="87.368011"
                                        height="90.618042"
                                        viewBox="-0.5 -0.5 87.368011 90.618042"
                                        animation="{&quot;scriptArr&quot;:[],&quot;cellIdArr&quot;:[]}"
                                        isstartanimationwithpreviouspage="with"
                                        id="svg4997"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={`arrowAnITA3 left ${components["pw"].isOn && components["vw"].isOpen ? "on" : ""}`}>
                                        <g
                                            id="g4985"
                                            transform="translate(-266.17001,-204)">
                                            <g
                                                shape-node=""
                                                id="g4953">
                                                <path
                                                    cell-id="dD5wv-8hnhxJDom6t97_-13"
                                                    d="m 265.67,204 h 33.66 q 10,0 10,10 v 65.5 q 0,10 10,10 h 27.34"
                                                    fill="none"
                                                    stroke="#000000"
                                                    strokeMiterlimit="10"
                                                    pointerEvents="stroke"
                                                    id="path4949" />
                                                <path
                                                    cell-id="dD5wv-8hnhxJDom6t97_-13"
                                                    d="m 351.92,289.5 -7,3.5 1.75,-3.5 -1.75,-3.5 z"
                                                    fill="#000000"
                                                    stroke="#000000"
                                                    strokeMiterlimit="10"
                                                    pointerEvents="all"
                                                    id="path4951" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div>
                                    <div className="valveAnITA false">
                                        {components["vw"] && (
                                            <GateValve
                                                isOpen={components["vw"].isOpen}
                                                key={"vw"}
                                            />
                                        )}
                                    </div>
                                    <p className="valveAnITAText">V2</p>

                                </div>
                                <svg
                                    version="1.1"
                                    width="66.343628"
                                    height="19.949997"
                                    viewBox="-0.5 -0.5 66.343628 19.949997"
                                    animation="{&quot;scriptArr&quot;:[],&quot;cellIdArr&quot;:[]}"
                                    isstartanimationwithpreviouspage="with"
                                    id="svg4997"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={`arrowAnITA2 left ${components["pw"].isOn ? "on" : ""}`}>
                                    <g
                                        id="g4985"
                                        transform="translate(-172.495,-201.12051)">
                                        <g
                                            shape-node=""
                                            id="g4959">
                                            <path
                                                cell-id="dD5wv-8hnhxJDom6t97_-23"
                                                d="m 172,204.67 59.97,0.6"
                                                fill="none"
                                                stroke="#000000"
                                                strokeMiterlimit="10"
                                                pointerEvents="stroke"
                                                id="path4955" />
                                            <path
                                                cell-id="dD5wv-8hnhxJDom6t97_-23"
                                                d="m 237.22,205.32 -7.04,3.43 1.79,-3.48 -1.72,-3.52 z"
                                                fill="#000000"
                                                stroke="#000000"
                                                strokeMiterlimit="10"
                                                pointerEvents="all"
                                                id="path4957" />
                                        </g>
                                    </g>
                                </svg>
                                <div>
                                    <div className={`pumpAnITA 
                                    left ${components.pw.enabled}`} style={{width: "4vw", height: "4vh"}}>
                                        {components["pw"] && (
                                            <PumpGeneral isOn={components["pw"].isOn}
                                                         key={"pw"}
                                                         onClick={() => handleComponentIsOnClick("pw")}
                                                         className="left"
                                            />
                                        )}
                                    </div>
                                    <p className="pumpAnITAText">P2</p>
                                    <div className={`pumpAnITA 
                                    ${components.pw.enabled}`} style={{width: "4vw", height: "4vh"}}>
                                        {components["pw"] && (
                                            <input className="textinput" type="text" id="pwInput"
                                                   placeholder="in ml"></input>
                                        )}
                                        <button className="textinput"
                                                onClick={() => handleComponentIsOnTextClick("pw")}>Start P2
                                        </button>
                                    </div>
                                </div>
                                <svg
                                    version="1.1"
                                    width="95.998032"
                                    height="11.236069"
                                    viewBox="-0.5 -0.5 95.998032 11.236069"
                                    animation="{&quot;scriptArr&quot;:[],&quot;cellIdArr&quot;:[]}"
                                    isstartanimationwithpreviouspage="with"
                                    id="svg831"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="left arrowAnITA1">
                                <g
                                        id="g819"
                                        transform="translate(-40.499999,-200.38196)">
                                        <g
                                            shape-node=""
                                            id="g799">
                                            <path
                                                cell-id="dD5wv-8hnhxJDom6t97_-40"
                                                d="m 40,205.5 h 88.13"
                                                fill="none"
                                                stroke="#000000"
                                                strokeMiterlimit="10"
                                                pointerEvents="stroke"
                                                id="path795" />
                                            <path
                                                cell-id="dD5wv-8hnhxJDom6t97_-40"
                                                d="m 134.88,205.5 -9,4.5 2.25,-4.5 -2.25,-4.5 z"
                                                fill="#000000"
                                                stroke="#000000"
                                                strokeMiterlimit="10"
                                                pointerEvents="all"
                                                id="path797" />
                                        </g>
                                    </g>
                                </svg>
                                <p>H2O</p>
                            </div>
                            <div>
                                <svg
                                    version="1.1"
                                    width="9.2371521"
                                    height="20.844452"
                                    viewBox="-0.5 -0.5 9.2371521 20.844452"
                                    animation="{&quot;scriptArr&quot;:[],&quot;cellIdArr&quot;:[]}"
                                    isstartanimationwithpreviouspage="with"
                                    id="svg831"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="arrowAnITA4"
                                >
                                    <g
                                        id="g819"
                                        transform="translate(-398.49614,-367.49414)">
                                        <g
                                            shape-node=""
                                            id="g775">
                                            <path
                                                cell-id="dD5wv-8hnhxJDom6t97_-25"
                                                d="m 402.75,367 -0.17,14.47"
                                                fill="none"
                                                stroke="#000000"
                                                strokeMiterlimit="10"
                                                pointerEvents="stroke"
                                                id="path771" />
                                            <path
                                                cell-id="dD5wv-8hnhxJDom6t97_-25"
                                                d="m 402.51,386.72 -3.41,-7.05 3.48,1.8 3.52,-1.71 z"
                                                fill="#000000"
                                                stroke="#000000"
                                                strokeMiterlimit="10"
                                                pointerEvents="all"
                                                id="path773" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div>
                                <div className={`valveAnITA3 ${components.vd.enabled}`}>
                                    {components["vd"] && (
                                        <GateValve
                                            isOpen={components["vd"].isOpen}
                                            key={"vd"}
                                            onClick={() => handleComponentIsOpenClick("vd")}
                                        />
                                    )}
                                </div>
                                <p className="valve3AnITAText">V3</p>
                            </div>

                            <div>
                                <svg
                                    version="1.1"
                                    width="217.66809"
                                    height="41.278229"
                                    viewBox="-0.5 -0.5 217.66809 41.278229"
                                    animation="{&quot;scriptArr&quot;:[],&quot;cellIdArr&quot;:[]}"
                                    isstartanimationwithpreviouspage="with"
                                    id="svg831"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={`arrowAnITA5 ${components["vd"].isOpen ? "on" : ""}`}>
                                    <g
                                        id="g819"
                                        transform="translate(-403.32997,-414.83981)">
                                        <g
                                            shape-node=""
                                            id="g817">
                                            <path
                                                cell-id="dD5wv-8hnhxJDom6t97_-31"
                                                d="M 403.34,414.34 403.33,441 q 0,10 10,10 h 200.8"
                                                fill="none"
                                                stroke="#000000"
                                                strokeMiterlimit="10"
                                                pointerEvents="stroke"
                                                id="path813" />
                                            <path
                                                cell-id="dD5wv-8hnhxJDom6t97_-31"
                                                d="m 619.38,451 -7,3.5 1.75,-3.5 -1.75,-3.5 z"
                                                fill="#000000"
                                                stroke="#000000"
                                                strokeMiterlimit="10"
                                                pointerEvents="all"
                                                id="path815" />
                                        </g>
                                    </g>
                                </svg>
                                <p className="wasteAnITAText">WASTE</p>
                            </div>

                        </div>
                        <div className="vertikal experimentAnITAInfos">
                            {
                                components.ac.isOn && <label>
                                    {currentTranslations.stirrerSpeedLabel} {stSpeed}
                                </label>
                            }
                            <label>

                            </label>
                            <label>
                                {currentTranslations.temperature}{temperature}
                            </label>

                            <Chart value={temperature} />
                        </div>
                    </div>
                    <div>
                        <label id="state"></label>
                    </div>
                    <div>
                        <HLSPlayer src="https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_ts/master.m3u8"></HLSPlayer>
                    </div>
                    <p>
                        Quelle: https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_ts/master.m3u8
                    </p>
                    <div className="vertikal experiment-form">
                        <label htmlFor="descriptionCheckbox" >
                            <input
                                type="checkbox"
                                id="descriptionCheckbox"
                                checked={showDescription}
                                onChange={handleCheckboxChange}
                            />
                            Anlagebeschreibung anzeigen
                        </label>
                    </div>
                    {showDescription && (
                        <div className="experiment-description">
                            <h2>Anlagebeschreibung</h2>
                            <p>Die Elemente P1 und P2 sind Pumpen. Die Elemente v1, v2 und v3 sind jeweils Ventile.</p>
                        </div>
                    )}

                </div>
            }
        </>
    )
}