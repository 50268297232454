import React, { useEffect } from "react";
import { useParams } from 'react-router-dom';
import ControlAnITA from "./anita/ControlAnITA.js"
import ControlVerA from "./vera/ControlVerA.js";
import yaml from 'js-yaml';
import { useState } from "react";
import { Link } from "react-router-dom";
import "./Control.css"
import { Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import translations from "./../../translations.json"
import { getRequestWithToken } from "../../http-requests/HTTPRequests.js";
import ControlSimpleAnITA from "./simpleanita/ControlSimpleAnITA.js";

const { dump } = yaml;
const currentTranslations = translations[sessionStorage.getItem('language')] || {};

/**
 * This function returns the ExpControl.
 * It is used to communicate for the experiment control.
 */
export default function ExpControl() {

    const { facilityId, appointmentId, connectedFacilityName } = useParams();
    let experimentComponent;
    const [state, setState] = useState(null);
    const [ready, setReady] = useState(false)
    const [signalMessage, setSignalMessage] = useState(null);
    const [stompClient, setStompClient] = useState(null);
    const [ecsURL, setECSURL] = useState("");


    async function getFacility() {
        getRequestWithToken("", `/api/management/facilities/getfacility/facilityname?facilityname=${connectedFacilityName}`, sessionStorage.getItem("token")).
            then(response => {
                const institutionURL = response.data.institution.institutionURL;
                setECSURL(institutionURL)
            }
            ).catch(error => {
                console.log(error)
            })
    }
    
    useEffect(() => {
        getFacility();
    }, [facilityId]);

    useEffect(() => {
        if (!ecsURL) {
            return;
        }
    const socket = new SockJS(`${ecsURL}/gateway`);
    const client = Stomp.over(socket);
    const onConnect = () => {
        console.log("Connected to STOMP server");
        client.subscribe(`/topic/${connectedFacilityName}`, function (message) {
            const messageObj = yaml.load(message.body);
            switch (messageObj['op-code']) {
                case 'stateresp':
                    console.log(messageObj.state)
                    setState({ messageState: messageObj.state, messageParams: messageObj.parameters });
                    setReady(true)
                    break;
                case 'signal':
                    if (messageObj.parameters) {
                        setSignalMessage({ signal: messageObj.signal, parameters: messageObj.parameters });
                        break;
                    }
                    setSignalMessage({ signal: messageObj.signal });
                    break;
                case 'idk':
                    console.log(messageObj.message);
                    break;
                default:
                    console.log("Unknown op-code: ", message.body['op-code']);
                    break;
            }
        });};
    client.connect({
        'Authorization': 'Bearer ' + sessionStorage.getItem("token"),
        'id': facilityId
    }, onConnect);
    setStompClient(client)
}, [ecsURL])
    
    function sendStateReq() {
        if (stompClient && stompClient.connected) {
            const yamlHeader = {
                version: '1',
                'op-code': 'statereq',
                jwt: sessionStorage.getItem("token"),
                facilityid: facilityId,
                appointmentid: appointmentId,
            }
            const signalToSend = dump(yamlHeader) + "\n\n"
            stompClient.send(`/topic/${connectedFacilityName}/gateway/servers`, {
                'Authorization': 'Bearer ' + sessionStorage.getItem("token"),
                'id': facilityId
            }, signalToSend)
        }
    }

    //This method gets called when the user controlled the experiment-facility. 
    //It sends a signal to the ECS.
    const handleSignal = (signal, parameters) => {
        console.log(stompClient.connected)
        if (stompClient && stompClient.connected) {
            const yamlHeader = {
                version: '1',
                'op-code': 'signal',
                jwt: sessionStorage.getItem("token"),
                facilityid: facilityId,
                appointmentid: appointmentId,
            }
            const bodySignal = {
                signal: "UV::"+signal,
                parameters: parameters
            }
            const signalToSend = dump(yamlHeader) + "\n\n" + dump(bodySignal);
            console.log("Signal to send:"+signalToSend)
            stompClient.send(`/topic/${connectedFacilityName}/gateway/servers`, {
                'Authorization': 'Bearer ' + sessionStorage.getItem("token"),
                'id': facilityId
            }, signalToSend);
        }
    }


    //Loads the experiment-facility-control component.
    switch (connectedFacilityName) {
        case 'AnITA':
            experimentComponent = <ControlAnITA experimentState={state} handleSignal={handleSignal} signalMessage={signalMessage} setSignalMessage={setSignalMessage} />
            break;
        case 'SimpleAnITA':
            experimentComponent = <ControlSimpleAnITA experimentState={state} handleSignal={handleSignal} signalMessage={signalMessage} setSignalMessage={setSignalMessage} />
            break;
        case 'VerA':
            experimentComponent = <ControlVerA experimentState={state} handleSignal={handleSignal} signalMessage={signalMessage} setSignalMessage={setSignalMessage} />
            break;
        default:
            console.log("Experiment facility not found")
    }



    //ID verwenden, um Inhalte zu laden. 
    //Etwas anderes als get item user verwenden
    return (
        <>{sessionStorage.getItem('user') &&
            <div className="center">
                {!ready ?
                    <div>
                        <button onClick={() => sendStateReq()}>{currentTranslations.startExpContr}</button>
                    </div>
                    :
                    <div>
                        {experimentComponent}
                    </div>
                }
                <div className="controlfaq">
                    <p>
                        {currentTranslations.problemsExpContr} <Link to={"/faq"}>FAQ</Link>
                    </p>
                </div>
            </div>
        }</>
    )
}
