import React from "react";
import { useState, useEffect } from "react";
import './VerA.css'
import ThreeWayGateValve from '../../exp-components/ThreeWayGateValve.js';
import PumpGeneral from "../../exp-components/PumpGeneral.js";
import Stirrer from "../../exp-components/Stirrer.js"
import { Vera } from "./VerAMachine.js"
import translations from "../../../translations.json"
import { useMachine } from "@xstate/react"
import HLSPlayer from "../livestream/Livestream.js";


/**
 * This function returns the ControlVerA.
 * It is used to display control the VerA.
 */
export default function ControlVerA({ experimentState, handleSignal, signalMessage }) {

    const currentTranslations = translations[sessionStorage.getItem("language")] || {}
    const [v1State, setV1State] = useState("")
    const [v2State, setV2State] = useState("");
    const [v3State, setV3State] = useState("");
    const [v4State, setV4State] = useState("");
    const [v5State, setV5State] = useState("");
    const [v6State, setV6State] = useState("");
    const [p1State, setP1State] = useState("");
    const [p2State, setP2State] = useState("");
    const [s1State, setS1State] = useState("");
    const [s2State, setS2State] = useState("");
    const [s3State, setS3State] = useState("");
    const [s4State, setS4State] = useState("");
    const { valvesAndPumpsState, valves1state, valves2state, valves3state, pumpsOnState, stirrer1State, stirrer2State, stirrer3State, stirrer4State, virtualSensorState, ledState, uvState, contextv1, contextv2, contextv3 } = setInitialState()
    const [state, send] = useMachine(() => Vera({ valvesAndPumpsState, valves1state, valves2state, valves3state, pumpsOnState, stirrer1State, stirrer2State, stirrer3State, stirrer4State, virtualSensorState, ledState, uvState, contextv1, contextv2, contextv3 }))

    const [showDescription, setShowDescription] = useState(false);

    const [components, setComponents] = useState({
        v1: { isOn: v1State === "V1On", enabled: valvesEnabled() },
        v2: { isOn: v2State === "V2On", enabled: valvesEnabled() },
        v3: { isOn: v3State === "V3On", enabled: valvesEnabled() },
        v4: { isOn: v4State === "V4On", enabled: valvesEnabled() },
        v5: { isOn: v5State === "V5On", enabled: valvesEnabled() },
        v6: { isOn: v6State === "V6On", enabled: valvesEnabled() },
        p1: { isOn: p1State === "P1On", enabled: pumpsEnabled() },
        p2: { isOn: p2State === "P2On", enabled: pumpsEnabled() },
        s1: { isOn: s1State === "S1On", enabled: true },
        s2: { isOn: s2State === "S2On", enabled: true },
        s3: { isOn: s3State === "S2On", enabled: true },
        s4: { isOn: s4State === "S4On", enabled: true },
    });

    useEffect(() => {
        update();
    }, [state])

    

    function pumpsEnabled() {
        if (state.value["ValvesAndPumps"]["PumpsOff"]) {
            const enableValves1 = state.value["ValvesAndPumps"]["PumpsOff"]["Valves1"];
            const enableValves2 = state.value["ValvesAndPumps"]["PumpsOff"]["Valves2"];
            const enableValves3 = state.value["ValvesAndPumps"]["PumpsOff"]["Valves3"]
            if (((enableValves1 === "V2OffV3On" || enableValves1 === "V2OnV3Off") && enableValves3 === "V1OnV6Off") || ((enableValves2 === "V4OffV5On" || enableValves2 === "V4OnV5Off") && enableValves3 === "V1OffV6On")) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    function setInitialState() {
        let valvesAndPumps = experimentState.messageState[0];
        let valves1 = experimentState.messageState[1]
        let valves2 = experimentState.messageState[2]
        let valves3 = experimentState.messageState[3]
        let pumpsOn = experimentState.messageState[4]
        let stirrer1 = experimentState.messageState[5]
        let stirrer2 = experimentState.messageState[6]
        let stirrer3 = experimentState.messageState[7]
        let stirrer4 = experimentState.messageState[8]
        let virtualSensor = experimentState.messageState[9]
        let led = experimentState.messageState[10]
        let uv = experimentState.messageState[11]
        let valvesAndPumpsState = "";
        let valves1state = "";
        let valves2state = "";
        let valves3state = "";
        let pumpsOnState = "";
        let stirrer1State = "";
        let stirrer2State = "";
        let stirrer3State = "";
        let stirrer4State = "";
        let virtualSensorState = "";
        let ledState = "";
        let uvState = "";
        let contextv1 = "";
        let contextv2 = "";
        let contextv3 = "";
        switch (valvesAndPumps) {
            case "1":
                valvesAndPumpsState = "PumpsOff"
                break;
            case "2":
                valvesAndPumpsState = "PumpsOn"
                break;
        }

        switch (valves1) {
            case "1":
                valves1state = "V2OnV3Off"
                contextv1 = valves1state
                // v2State = "V2On"
                // v3State = "V3Off"
                break;
            case "2":
                valves1state = "V2OffV3On"
                contextv1 = valves1state
                // v2State = "V2Off"
                // v3State = "V3On"
                break;
            case "3":
                valves1state = "V2OffV3Off"
                contextv1 = valves1state;
                // v2State = "V2Off"
                // v3State = "V3Off"
                break;
            case "4":
                valves1state = "V2OnV3On"
                contextv1 = valves1state;
                // v2State = "V2On"
                // v3State = "V3On"
                break;
        }

        switch (valves2) {
            case "1":
                valves2state = "V4OnV5Off"
                contextv2 = valves2state;
                // v4State = "V4On"
                // v5State = "V5Off"

                break;
            case "2":
                valves2state = "V4OffV5On"
                contextv2 = valves2state;
                // v4State = "V4Off"
                // v5State = "V5On"
                break;
            case "3":
                valves2state = "V4OffV5Off"
                contextv2 = valves2state;
                // v4State = "V4Off"
                // v5State = "V5Off"
                break;
            case "4":
                valves2state = "V4OnV5On"
                contextv2 = valves2state;
                // v4State = "V4On"
                // v5State = "V5On"
                break;
        }

        switch (valves3) {
            case "1":
                valves3state = "V1OnV6Off"
                contextv3 = valves3state;
                // v1State = "V1On"
                // v6State = "V6Off"
                break;
            case "2":
                valves3state = "V1OffV6On"
                contextv3 = valves3state;
                // v1State = "V1Off"
                // v6State = "V6On"
                break;
            case "3":
                valves3state = "V1OffV6Off"
                contextv3 = valves3state;
                // v1State = "V1Off"
                // v6State = "V6Off"
                break;
            case "4":
                valves3state = "V1OnV6On"
                contextv3 = valves3state;
                // v1State = "V1On"
                // v6State = "V6On"
                break;
        }
        switch (pumpsOn) {
            case "0":
                pumpsOnState = undefined
                break;
            case "1":
                pumpsOnState = "P1OnP2Off"
                break;
            case "2":
                pumpsOnState = "P1OnP2On"
                break;
            case "3":
                pumpsOnState = "P1OffP2On"
                break;
        }

        switch (stirrer1) {
            case "1":
                stirrer1State = "S1Off"
                break;
            case "2":
                stirrer1State = "S1On"
                break;
        }

        switch (stirrer2) {
            case "1":
                stirrer2State = "S2Off"
                break;
            case "2":
                stirrer2State = "S2On"
                break;
        }

        switch (stirrer3) {
            case "1":
                stirrer3State = "S3Off"
                break;
            case "2":
                stirrer3State = "S3On"
                break;
        }

        switch (stirrer4) {
            case "1":
                stirrer4State = "S4Off"
                break;
            case "2":
                stirrer4State = "S4On"
                break;
        }

        switch (virtualSensor) {
            case "1":
                virtualSensorState = "TankAlmostEmpty"
                break;
            case "2":
                virtualSensorState = "TankEmpty"
                break;
            case "3":
                virtualSensorState = "TankFull"
                break;
        }


        switch (led) {
            case "1":
                ledState = "LEDOff"
                break;
            case "2":
                ledState = "LEDOn"
                break;
        }

        switch (uv) {
            case "1":
                uvState = "UVOff"
                break;
            case "2":
                uvState = "UVOn"
                break;
        }

        return {
            valvesAndPumpsState: valvesAndPumpsState,
            valves1state: valves1state,
            valves2state: valves2state,
            valves3state: valves3state,
            pumpsOnState: pumpsOnState,
            stirrer1State: stirrer1State,
            stirrer2State: stirrer2State,
            stirrer3State: stirrer3State,
            stirrer4State: stirrer4State,
            virtualSensorState: virtualSensorState,
            ledState: ledState,
            uvState: uvState,
            contextv1: contextv1,
            contextv2: contextv2,
            contextv3: contextv3
        }
    }


    function valvesEnabled() {
        if (state.value["ValvesAndPumps"]["PumpsOff"]) {
                return true;
            } else {
                return false;
            }
    }

    function update() {
        setS1State(state.value["Stirrer"]["Stirrer1"])
        setS2State(state.value["Stirrer"]["Stirrer2"])
        setS3State(
            state.value["Stirrer"]["Stirrer3"]
        )
        setS4State(state.value["Stirrer"]["Stirrer4"])
        if (state.value["ValvesAndPumps"]["PumpsOff"]) {
            const [v2StateNew, v3StateNew] = state.value["ValvesAndPumps"]["PumpsOff"]["Valves1"].split(/(?=V3)/);
            const [v4StateNew, v5StateNew] = state.value["ValvesAndPumps"]["PumpsOff"]["Valves2"].split(/(?=V5)/);
            const [v1StateNew, v6StateNew] = state.value["ValvesAndPumps"]["PumpsOff"]["Valves3"].split(/(?=V6)/);
            setV1State(v1StateNew);
            setV2State(v2StateNew);
            setV3State(v3StateNew);
            setV4State(v4StateNew)
            setV5State(v5StateNew);
            setV6State(v6StateNew)
            setComponents({
                v1: { isOn: v1StateNew === 'V1On', enabled: valvesEnabled() },
                v2: { isOn: v2StateNew === 'V2On', enabled: valvesEnabled() },
                v3: { isOn: v3StateNew === 'V3On', enabled: valvesEnabled() },
                v4: { isOn: v4StateNew === 'V4On', enabled: valvesEnabled() },
                v5: { isOn: v5StateNew === 'V5On', enabled: valvesEnabled() },
                v6: { isOn: v6StateNew === 'V6On', enabled: valvesEnabled() },
                p1: { isOn: false, enabled: pumpsEnabled() },
                p2: { isOn: false, enabled: pumpsEnabled() },
                s1: { isOn: state.value["Stirrer"]["Stirrer1"] === 'S1On', enabled: true },
                s2: { isOn: state.value["Stirrer"]["Stirrer2"] === 'S2On', enabled: true },
                s3: { isOn: state.value["Stirrer"]["Stirrer3"] === 'S3On', enabled: true },
                s4: { isOn: state.value["Stirrer"]["Stirrer4"] === 'S4On', enabled: true },
            })
        }
        if (state.value["ValvesAndPumps"]["PumpsOn"]) {
            const [p1StateNew, p2StateNew] = state.value["ValvesAndPumps"]["PumpsOn"].split(/(?=P2)/);
            setP1State(p1StateNew)
            setP2State(p2StateNew)
            setComponents(prevState => ({
                ...prevState,
                p1: { isOn: p1StateNew === 'P1On', enabled: pumpsEnabled() },
                p2: { isOn: p2StateNew === 'P2On', enabled: pumpsEnabled() },
                s1: { isOn: s1State === 'S1On', enabled: true },
                s2: { isOn: s2State === 'S2On', enabled: true },
                s3: { isOn: s3State === 'S3On', enabled: true },
                s4: { isOn: s4State === 'S4On', enabled: true },
            }))
            components.v1.enabled = false;
            components.v2.enabled = false;
            components.v3.enabled = false;
            components.v4.enabled = false;
            components.v5.enabled = false;
            components.v6.enabled = false;
        }

    }

    function handleCheckboxChange() {
        setShowDescription(!showDescription);
    }

    useEffect(() => {
        if (signalMessage) {
            if (signalMessage.signal.startsWith("conf")) {
                const signalState = signalMessage.signal.slice(4)
                send(`${signalState}`);
                document.getElementById("state").innerHTML = currentTranslations.controlActionSucc
            }
            if (signalMessage.signal.startsWith("update")) {
                //TODO: synchronize Update
            }
        }
    }, [signalMessage]);

    function handleComponentIsOpenClick(componentKey) {
        document.getElementById("state").innerHTML = currentTranslations.controlAction
        const isOn = !components[componentKey].isOn;
        const signalState = `${componentKey}${isOn ? "on" : "off"}`;
        if (state.nextEvents.includes(signalState)) {
            handleSignal(
                signalState, null
            )
        } else {
            document.getElementById("state").innerHTML = currentTranslations.controlActionFailed
        }
    }

    function handleComponentIsOnClick(componentKey) {
        document.getElementById("state").innerHTML = currentTranslations.controlAction
        const isOn = !components[componentKey].isOn;
        const signalState = `${componentKey}${isOn ? "on" : "off"}`;
        if (state.nextEvents.includes(signalState)) {
            handleSignal(
                signalState, null
            )
            console.log(state)
        } else {
            document.getElementById("state").innerHTML = currentTranslations.controlActionFailed
        }
    }

    return (
        <>{sessionStorage.getItem('user') &&
            <div className="center">
                <h1>VerA</h1>
                <div className={`horizontalVerA`}>
                    <div className="center veramodel">
                        <div className="component-container veracontainer">
                            <div className="modelvera">
                                <div className="vertikalVerA">
                                    <div className="horizontalVerAModel vera1 ">
                                        <svg
                                            width="19.579704mm"
                                            height="15.111229mm"
                                            viewBox="0 0 19.579704 15.111229"
                                            version="1.1"
                                            id="svg5"
                                            xmlns="http://www.w3.org/2000/svg"
                                        ><g
                                            id="layer1"
                                            transform="translate(-95.463251,-34.628859)"><rect
                                                    style={{ opacity: "1", fill: "#000000", fillOpacity: "1", fillRule: "evenodd", stroke: "#000000", strokeWidth: "0.865", strokeDasharray: "none", strokeOpacity: "1", paintOrder: "markers stroke fill" }}
                                                    id="rect14288"
                                                    width="2.6120737"
                                                    height="4.2446194"
                                                    x="95.895752"
                                                    y="40.911598" /><path
                                                    cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                    d="m 97.201787,46.058633 -0.92604,1.85208 0.92604,-0.46302 0.92604,0.46302 z"
                                                    fill="#000000"
                                                    stroke="#000000"
                                                    strokeMiterlimit="10"
                                                    pointerEvents="all"
                                                    id="path325-1-0"
                                                    style={{ strokeWidth: "0.264583" }} /><path
                                                    cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                    d="m 97.201307,49.73977 -0.0055,-2.274138"
                                                    fill="none"
                                                    stroke="#000000"
                                                    strokeMiterlimit="10"
                                                    pointerEvents="stroke"
                                                    id="path323-7"
                                                    style={{ strokeWidth: "0.264583" }} /><path
                                                    cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                    d="m 114.74715,35.850709 -1.85208,-0.92604 0.46302,0.92604 -0.46302,0.92604 z"
                                                    fill="#000000"
                                                    stroke="#000000"
                                                    strokeMiterlimit="10"
                                                    pointerEvents="all"
                                                    id="path325-1-0-7"
                                                    style={{ strokeWidth: "0.264583" }} /><path
                                                    cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                    d="m 97.251439,39.994396 -0.0046,-4.127316 16.161811,-0.0079"
                                                    fill="none"
                                                    stroke="#000000"
                                                    strokeMiterlimit="10"
                                                    pointerEvents="stroke"
                                                    id="path323-7-9"
                                                    style={{ strokeWidth: "0.264583" }} /></g></svg>
                                        <p className="verawaste">Waste</p>
                                    </div>
                                    <div className="horizontalVerAModel verav6container">
                                        <svg
                                            width="21.224289mm"
                                            height="6.4946175mm"
                                            viewBox="0 0 21.224289 6.4946175"
                                            version="1.1"
                                            id="svg5"
                                            xmlns="http://www.w3.org/2000/svg"
                                        ><g
                                            id="layer1"
                                            transform="translate(-72.028032,-52.193888)"><path
                                                    cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                    d="m 72.16053,58.687784 0.0286,-5.275537 19.412795,0.02309"
                                                    fill="none"
                                                    stroke="#000000"
                                                    strokeMiterlimit="10"
                                                    pointerEvents="stroke"
                                                    id="path323-8"
                                                    style={{ strokeWidth: "0.265", strokeDasharray: "none" }} /><path
                                                    cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                    d="m 92.956036,53.416206 -1.852081,-0.92604 0.46302,0.92604 -0.46302,0.92604 z"
                                                    fill="#000000"
                                                    stroke="#000000"
                                                    strokeMiterlimit="10"
                                                    pointerEvents="all"
                                                    id="path325-3"
                                                    style={{ strokeWidth: "0.265", strokeDasharray: "none" }} /></g></svg>
                                        <div >
                                            <div className={`threeWayValveVera ${components.v6.enabled} verarotate`} >
                                                {components["v6"] && (
                                                    <ThreeWayGateValve isOpen={components["v6"].isOn}
                                                        key={"v6"}
                                                        onClick={() => handleComponentIsOpenClick("v6")}
                                                    />
                                                )}
                                            </div>
                                            <p className="threeWayValveVeraText">V6</p>
                                        </div>
                                        <svg
                                            width="21.363441mm"
                                            height="6.4401474mm"
                                            viewBox="0 0 21.363441 6.4401474"
                                            version="1.1"
                                            id="svg5"
                                            xmlns="http://www.w3.org/2000/svg"
                                        ><g
                                            id="layer1"
                                            transform="translate(-100.94288,-52.293005)"><path
                                                    cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                    d="m 122.17403,58.732435 -0.0286,-5.275537 -19.41279,0.02309"
                                                    fill="none"
                                                    stroke="#000000"
                                                    strokeMiterlimit="10"
                                                    pointerEvents="stroke"
                                                    id="path323"
                                                    style={{ strokeWidth: "0.264583" }} /><path
                                                    cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                    d="m 101.23869,53.514858 1.85208,-0.92604 -0.46302,0.92604 0.46302,0.92604 z"
                                                    fill="#000000"
                                                    stroke="#000000"
                                                    strokeMiterlimit="10"
                                                    pointerEvents="all"
                                                    id="path325-1"
                                                    style={{ strokeWidth: "0.264583" }} /></g></svg>
                                    </div>
                                    <div className="horizontalVerAModel ">
                                        <div className="vertikalVerA valvesleftvera">

                                            <div >
                                                <div className={`threeWayValveVera ${components.v3.enabled} verarotate`} >
                                                    {components["v3"] && (
                                                        <ThreeWayGateValve isOpen={components["v3"].isOn}
                                                            key={"v3"}
                                                            onClick={() => handleComponentIsOpenClick("v3")}
                                                        />
                                                    )}
                                                </div>
                                                <p className="threeWayValveVeraText">V3</p>
                                            </div>
                                            <div className="flowtubevera">
                                                <svg
                                                    width="26.999378mm"
                                                    height="52.827854mm"
                                                    viewBox="0 0 26.999378 52.827854"
                                                    version="1.1"
                                                    id="svg5"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                ><g
                                                    id="layer1"
                                                    transform="translate(-58.672002,-61.14094)"><rect
                                                            style={{ mixBlendMode: "normal", fill: "#494949", fillOpacity: "1", stroke: "#000000", strokeWidth: "0.865", strokeDasharray: "none", strokeOpacity: "1", paintOrder: "markers stroke fill" }}
                                                            id="rect7465"
                                                            width="3.7863805"
                                                            height="40.080223"
                                                            x="81.452499"
                                                            y="67.932693" /><rect
                                                            style={{ fill: "#4a4a4a", fillOpacity: "1", stroke: "#000000", strokeWidth: "0.865", strokeDasharray: "none", paintOrder: "markers stroke fill" }}
                                                            id="rect7465-4"
                                                            width="3.7863805"
                                                            height="40.080223"
                                                            x="59.1045"
                                                            y="67.932503" /><ellipse
                                                            style={{ fill: "#ffffff", stroke: "none", strokeWidth: "8.96918" }}
                                                            cx="82.805458"
                                                            cy="70.680313"
                                                            id="circle10826"
                                                            rx="0.4393644"
                                                            ry="0.45774198" /><ellipse
                                                            style={{ fill: "#ffffff", stroke: "none", strokeWidth: "8.96918" }}
                                                            cx="83.375282"
                                                            cy="103.19337"
                                                            id="circle10830"
                                                            rx="0.4393644"
                                                            ry="0.45774198" /><ellipse
                                                            style={{ fill: "#ffffff", stroke: "none", strokeWidth: "8.96918" }}
                                                            cx="82.709068"
                                                            cy="88.023621"
                                                            id="circle10844"
                                                            rx="0.4393644"
                                                            ry="0.45774198" /><ellipse
                                                            style={{ fill: "#ffffff", stroke: "none", strokeWidth: "8.96918" }}
                                                            cx="83.963394"
                                                            cy="80.866135"
                                                            id="circle10846"
                                                            rx="0.4393644"
                                                            ry="0.45774198" /><ellipse
                                                            style={{ fill: "#ffffff", stroke: "none", strokeWidth: "8.96918" }}
                                                            cx="83.837242"
                                                            cy="97.524742"
                                                            id="circle10850"
                                                            rx="0.4393644"
                                                            ry="0.45774198" /><path
                                                            cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                            d="m 83.392724,108.87899 -0.92604,1.85208 0.92604,-0.46302 0.92604,0.46302 z"
                                                            fill="#000000"
                                                            stroke="#000000"
                                                            strokeMiterlimit="10"
                                                            pointerEvents="all"
                                                            id="path325-4-0-5-4"
                                                            style={{ strokeWidth: "0.264583" }} /><path
                                                            cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                            d="m 60.951492,108.879 -0.92604,1.85208 0.92604,-0.46302 0.92604,0.46302 z"
                                                            fill="#000000"
                                                            stroke="#000000"
                                                            strokeMiterlimit="10"
                                                            pointerEvents="all"
                                                            id="path325-4-0-5-4-8"
                                                            style={{ strokeWidth: "0.264583" }} /><path
                                                            cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                            d="m 60.928752,110.38337 0.0856,3.44565 c 6.957,0.0242 0.01513,-0.0178 6.972189,-0.0216"
                                                            fill="none"
                                                            stroke="#000000"
                                                            strokeMiterlimit="10"
                                                            pointerEvents="stroke"
                                                            id="path323-6-15-2-0-7-7-8"
                                                            style={{ strokeWidth: "0.264583" }} /><path
                                                            cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                            d="m 83.435862,110.32417 -0.0069,3.44446 -7.229211,0.0663"
                                                            fill="none"
                                                            stroke="#000000"
                                                            strokeMiterlimit="10"
                                                            pointerEvents="stroke"
                                                            id="path323-6-15-2-0-7-7-8-1"
                                                            style={{ strokeWidth: "0.264583" }} /><path
                                                            cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                            d="m 67.991029,62.428559 -1.852081,-0.92604 0.46302,0.92604 -0.46302,0.92604 z"
                                                            fill="#000000"
                                                            stroke="#000000"
                                                            strokeMiterlimit="10"
                                                            pointerEvents="all"
                                                            id="path325-3-5"
                                                            style={{ strokeWidth: "0.265", strokeDasharray: "none" }} /><path
                                                            cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                            d="m 76.52194,62.363256 1.852081,-0.92604 -0.46302,0.92604 0.46302,0.92604 z"
                                                            fill="#000000"
                                                            stroke="#000000"
                                                            strokeMiterlimit="10"
                                                            pointerEvents="all"
                                                            id="path325-3-1"
                                                            style={{ strokeWidth: "0.265", strokeDasharray: "none" }} /><path
                                                            cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                            d="m 61.034713,67.221955 -0.0041,-4.785773 5.536154,-0.04221"
                                                            fill="none"
                                                            stroke="#000000"
                                                            strokeMiterlimit="10"
                                                            pointerEvents="stroke"
                                                            id="path323-8-4"
                                                            style={{ strokeWidth: "0.265", strokeDasharray: "none" }} /><path
                                                            cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                            d="m 83.378598,67.194305 0.0041,-4.785773 -5.536154,-0.04221"
                                                            fill="none"
                                                            stroke="#000000"
                                                            strokeMiterlimit="10"
                                                            pointerEvents="stroke"
                                                            id="path323-8-4-7"
                                                            style={{ strokeWidth: "0.265", strokeDasharray: "none" }} /></g></svg>
                                            </div>
                                            <div className="v2vera">
                                                <div className={`threeWayValveVera ${components.v2.enabled}`} >
                                                    {components["v2"] && (
                                                        <ThreeWayGateValve isOpen={components["v2"].isOn}
                                                            key={"v2"}
                                                            onClick={() => handleComponentIsOpenClick("v2")}

                                                        />
                                                    )}
                                                </div>
                                                <p className="threeWayValveVeraText">V2</p>
                                            </div>
                                        </div>
                                        <div className="vertikalVerA stirrerrightvera">

                                            <div className="vertikalVerA">
                                                <div className="horizontalVerAModel">

                                                    <div className="vertikalVerA">
                                                        <svg
                                                            width="11.720398mm"
                                                            height="5.2686844mm"
                                                            viewBox="0 0 11.720398 5.2686844"
                                                            version="1.1"
                                                            id="svg5"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        ><g
                                                            id="layer1"
                                                            transform="translate(-106.53158,-61.315962)"><path
                                                                    cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                                    d="m 117.95617,62.537812 -1.85208,-0.92604 0.46302,0.92604 -0.46302,0.92604 z"
                                                                    fill="#000000"
                                                                    stroke="#000000"
                                                                    strokeMiterlimit="10"
                                                                    pointerEvents="all"
                                                                    id="path325"
                                                                    style={{ strokeWidth: "0.264583" }} /><path
                                                                    cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                                    d="m 106.67007,66.584445 -0.006,-4.035249 9.84244,0.0079"
                                                                    fill="none"
                                                                    stroke="#000000"
                                                                    strokeMiterlimit="10"
                                                                    pointerEvents="stroke"
                                                                    id="path323-6"
                                                                    style={{ strokeWidth: "0.264583" }} /></g></svg>
                                                        <div className="vertikalVerA">
                                                            <div className="s3vera">
                                                                <div className={`stirrerVera ${components.s3.enabled}`} >
                                                                    {components["s3"] && (
                                                                        <Stirrer isOn={components["s3"].isOn}
                                                                            key={"s3"}
                                                                            onClick={() => handleComponentIsOnClick("s3")}

                                                                        />
                                                                    )}
                                                                </div>
                                                                <p className="stirrerVeraText">S3</p>
                                                            </div>
                                                            <div className="arrows2tos3">
                                                                <svg
                                                                    width="8.5701294mm"
                                                                    height="12.399292mm"
                                                                    viewBox="0 0 8.5701294 12.399292"
                                                                    version="1.1"
                                                                    id="svg5"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <g
                                                                        id="layer1"
                                                                        transform="translate(-106.61193,-68.912321)"><path
                                                                            cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                                            d="m 109.89119,70.134176 1.85208,-0.92604 -0.46302,0.92604 0.46302,0.92604 z"
                                                                            fill="#000000"
                                                                            stroke="#000000"
                                                                            strokeMiterlimit="10"
                                                                            pointerEvents="all"
                                                                            id="path325-7"
                                                                            style={{ strokeWidth: "0.264583" }} /><path
                                                                            cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                                            d="M 106.77094,81.310022 106.74582,79.219 h 8.28325 l 0.0204,-9.108186 -3.56073,0.0023"
                                                                            fill="none"
                                                                            stroke="#000000"
                                                                            strokeMiterlimit="10"
                                                                            pointerEvents="stroke"
                                                                            id="path323-6-15"
                                                                            style={{ strokeWidth: "0.264583" }} /></g></svg>
                                                            </div>
                                                            <div className="s2vera" >
                                                                <div className={`stirrerVera ${components.s2.enabled}`} >
                                                                    {components["s2"] && (
                                                                        <Stirrer isOn={components["s2"].isOn}
                                                                            key={"s2"}
                                                                            onClick={() => handleComponentIsOnClick("s2")}

                                                                        />
                                                                    )}
                                                                </div>
                                                                <p className="stirrerVeraText">S2</p>
                                                            </div>
                                                            <div className="arrows1tos2">
                                                                <svg
                                                                    width="8.5701294mm"
                                                                    height="12.399292mm"
                                                                    viewBox="0 0 8.5701294 12.399292"
                                                                    version="1.1"
                                                                    id="svg5"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <g
                                                                        id="layer1"
                                                                        transform="translate(-106.61193,-68.912321)"><path
                                                                            cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                                            d="m 109.89119,70.134176 1.85208,-0.92604 -0.46302,0.92604 0.46302,0.92604 z"
                                                                            fill="#000000"
                                                                            stroke="#000000"
                                                                            strokeMiterlimit="10"
                                                                            pointerEvents="all"
                                                                            id="path325-7"
                                                                            style={{ strokeWidth: "0.264583" }} /><path
                                                                            cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                                            d="M 106.77094,81.310022 106.74582,79.219 h 8.28325 l 0.0204,-9.108186 -3.56073,0.0023"
                                                                            fill="none"
                                                                            stroke="#000000"
                                                                            strokeMiterlimit="10"
                                                                            pointerEvents="stroke"
                                                                            id="path323-6-15"
                                                                            style={{ strokeWidth: "0.264583" }} /></g></svg>
                                                            </div>
                                                            <div className="s1vera">
                                                                <div className={`stirrerVera ${components.s1.enabled}`} >
                                                                    {components["s1"] && (
                                                                        <Stirrer isOn={components["s1"].isOn}
                                                                            key={"s1"}
                                                                            onClick={() => handleComponentIsOnClick("s1")}

                                                                        />
                                                                    )}
                                                                </div>
                                                                <p className="stirrerVeraText">S1</p>
                                                            </div>
                                                            <div className="verav4tos1">
                                                                <svg
                                                                    width="8.358551mm"
                                                                    height="15.2314mm"
                                                                    viewBox="0 0 8.358551 15.2314"
                                                                    version="1.1"
                                                                    id="svg5"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                ><g
                                                                    id="layer1"
                                                                    transform="translate(-109.59537,-98.885861)"><path
                                                                            cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                                            d="m 109.89118,100.10772 1.85208,-0.926041 -0.46302,0.926041 0.46302,0.92604 z"
                                                                            fill="#000000"
                                                                            stroke="#000000"
                                                                            strokeMiterlimit="10"
                                                                            pointerEvents="all"
                                                                            id="path325-4"
                                                                            style={{ strokeWidth: "0.264583" }} /><path
                                                                            cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                                            d="m 117.95318,113.96673 -3.13319,0.0175 0.0517,-13.92533 -3.56073,0.002"
                                                                            fill="none"
                                                                            stroke="#000000"
                                                                            strokeMiterlimit="10"
                                                                            pointerEvents="stroke"
                                                                            id="path323-6-15-2-0"
                                                                            style={{ strokeWidth: "0.264583" }} /></g></svg>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="vertikalVerA">
                                                        <div className="v5vera">

                                                            <div className={`threeWayValveVera ${components.v5.enabled} verarotate`} >
                                                                {components["v5"] && (
                                                                    <ThreeWayGateValve isOpen={components["v5"].isOn}
                                                                        key={"v5"}
                                                                        onClick={() => handleComponentIsOpenClick("v5")}

                                                                    />
                                                                )}
                                                            </div>
                                                            <p className="threeWayValveVeraText">V5</p>
                                                        </div>
                                                        <div className="v4vera">
                                                            <div className={`threeWayValveVera ${components.v4.enabled}`} >
                                                                {components["v4"] && (
                                                                    <ThreeWayGateValve isOpen={components["v4"].isOn}
                                                                        key={"v4"}
                                                                        onClick={() => handleComponentIsOpenClick("v4")}

                                                                    />
                                                                )}
                                                            </div>
                                                            <p className="threeWayValveVeraText">V4</p>
                                                        </div>
                                                    </div>
                                                    <div className="vertikalVerA">
                                                        <div className="veras4tov5">
                                                            <svg
                                                                width="10.718384mm"
                                                                height="20.053223mm"
                                                                viewBox="0 0 10.718384 20.053223"
                                                                version="1.1"
                                                                id="svg5"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            ><g
                                                                id="layer1"
                                                                transform="translate(-126.11673,-61.337309)"><path
                                                                        cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                                        d="m 126.41254,62.559162 1.85208,-0.92604 -0.46302,0.92604 0.46302,0.92604 z"
                                                                        fill="#000000"
                                                                        stroke="#000000"
                                                                        strokeMiterlimit="10"
                                                                        pointerEvents="all"
                                                                        id="path325-8"
                                                                        style={{ strokeWidth: "0.264583" }} /><path
                                                                        cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                                        d="m 136.70282,81.389929 -0.0864,-18.857544 -8.78041,0.0079"
                                                                        fill="none"
                                                                        stroke="#000000"
                                                                        strokeMiterlimit="10"
                                                                        pointerEvents="stroke"
                                                                        id="path323-6-1"
                                                                        style={{ strokeWidth: "0.264583" }} /></g></svg>
                                                        </div>
                                                        <div className="s4vera" >
                                                            <div className={`stirrerVera ${components.s4.enabled}`} >
                                                                {components["s4"] && (
                                                                    <Stirrer isOn={components["s4"].isOn}
                                                                        key={"s4"}
                                                                        onClick={() => handleComponentIsOnClick("s4")}
                                                                    />
                                                                )}
                                                            </div>
                                                            <p className="stirrerVeraText">S4</p>
                                                        </div>
                                                        <div className="verav4tos4">
                                                            <svg
                                                                width="27.83287mm"
                                                                height="30.175087mm"
                                                                viewBox="0 0 27.83287 30.175087"
                                                                version="1.1"
                                                                id="svg5"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            ><g
                                                                id="layer1"
                                                                transform="translate(-126.36844,-83.997045)"><path
                                                                        cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                                        d="m 139.99532,85.2189 1.85208,-0.92604 -0.46302,0.92604 0.46302,0.92604 z"
                                                                        fill="#000000"
                                                                        stroke="#000000"
                                                                        strokeMiterlimit="10"
                                                                        pointerEvents="all"
                                                                        id="path325-38"
                                                                        style={{ strokeWidth: "0.264583" }} /><path
                                                                        cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                                        d="m 126.36867,114.03984 27.68668,-0.0478 0.0136,-28.814151 -12.99329,-0.03065"
                                                                        fill="none"
                                                                        stroke="#000000"
                                                                        strokeMiterlimit="10"
                                                                        pointerEvents="stroke"
                                                                        id="path323-6-15-2-0-3"
                                                                        style={{ strokeWidth: "0.264583" }} /></g></svg>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                    </div>
                                    <div className="horizontalVerAModel verav1container ">
                                        <div className="verav1tov2">
                                            <svg
                                                width="22.136841mm"
                                                height="5.4056396mm"
                                                viewBox="0 0 22.136841 5.4056396"
                                                version="1.1"
                                                id="svg5"
                                                xmlns="http://www.w3.org/2000/svg"
                                            ><g
                                                id="layer1"
                                                transform="translate(-70.93905,-117.86107)"><path
                                                        cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                        d="m 72.160898,118.15689 -0.92604,1.85208 0.92604,-0.46302 0.92604,0.46302 z"
                                                        fill="#000000"
                                                        stroke="#000000"
                                                        strokeMiterlimit="10"
                                                        pointerEvents="all"
                                                        id="path325-4-0-5"
                                                        style={{ strokeWidth: "0.264583" }} /><path
                                                        cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                        d="m 72.119238,119.61786 0.0856,3.44565 c 6.957,0.0242 13.913916,0.0746 20.870976,0.0707"
                                                        fill="none"
                                                        stroke="#000000"
                                                        strokeMiterlimit="10"
                                                        pointerEvents="stroke"
                                                        id="path323-6-15-2-0-7-7"
                                                        style={{ strokeWidth: "0.264583" }} /></g></svg>
                                        </div>
                                        <div className="v1vera">

                                            <div className={`threeWayValveVera ${components.v1.enabled}`} >
                                                {components["v1"] && (
                                                    <ThreeWayGateValve isOpen={components["v1"].isOn}
                                                        key={"v1"}
                                                        onClick={() => handleComponentIsOpenClick("v1")}

                                                    />
                                                )}
                                            </div>
                                            <p className="threeWayValveVeraText">V1</p>
                                        </div>
                                        <div className="verav1tov4">
                                            <svg
                                                width="22.136833mm"
                                                height="5.4056396mm"
                                                viewBox="0 0 22.136833 5.4056396"
                                                version="1.1"
                                                id="svg5"
                                                xmlns="http://www.w3.org/2000/svg"
                                            ><g
                                                id="layer1"
                                                transform="translate(-101.21888,-117.77208)"><path
                                                        cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                        d="m 122.13387,118.06789 0.92604,1.85208 -0.92604,-0.46302 -0.92604,0.46302 z"
                                                        fill="#000000"
                                                        stroke="#000000"
                                                        strokeMiterlimit="10"
                                                        pointerEvents="all"
                                                        id="path325-4-0"
                                                        style={{ strokeWidth: "0.264583" }} /><path
                                                        cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                        d="m 122.17554,119.52886 -0.0856,3.44565 c -6.95701,0.0242 -13.91392,0.0746 -20.87098,0.0707"
                                                        fill="none"
                                                        stroke="#000000"
                                                        strokeMiterlimit="10"
                                                        pointerEvents="stroke"
                                                        id="path323-6-15-2-0-7"
                                                        style={{ strokeWidth: "0.264583" }} /></g></svg>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="horizontalVerAModel pumpcontainervera ">
                                            <div className="vertikalVerA">
                                                <div className="horizontalVerAModel">
                                                    <div className="tankvera">
                                                        <svg
                                                            width="7.5194931mm"
                                                            height="18.840889mm"
                                                            viewBox="0 0 7.5194931 18.840889"
                                                            version="1.1"
                                                            id="svg5"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className={`arrowvera`}
                                                        ><g
                                                            id="layer1"
                                                            transform="translate(-35.685716,-110.28675)"><rect
                                                                    style={{ opacity: "1", fill: "green", fillOpacity: "1", fillRule: "evenodd", stroke: "#ffffff", strokeWidth: "0.865", strokeLinecap: "round", strokeDasharray: "none", strokeOpacity: "1", paintOrder: "fill markers stroke" }}
                                                                    id="rect17420"
                                                                    width="4.3752232"
                                                                    height="7.9015222"
                                                                    x="38.397484"
                                                                    y="110.71925"
                                                                    ry="0.32650921" /><path
                                                                    cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                                    d="m 37.988879,127.83047 -2.159962,0.0306 -0.01064,-5.12542 4.74353,-0.0429 0.01207,-3.09514"
                                                                    fill="none"
                                                                    stroke="#000000"
                                                                    strokeMiterlimit="10"
                                                                    pointerEvents="stroke"
                                                                    id="path323-6-15-2-0-7-7-8-6-0"
                                                                    style={{ strokeWidth: "0.264583" }} /><path
                                                                    cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                                    d="m 39.34395,127.90579 -1.85208,-0.92604 0.46302,0.92604 -0.46302,0.92604 z"
                                                                    fill="#000000"
                                                                    stroke="#000000"
                                                                    strokeMiterlimit="10"
                                                                    pointerEvents="all"
                                                                    id="path325-4-0-5-4-8-5-4-4"
                                                                    style={{ strokeWidth: "0.264583" }} /></g></svg></div>
                                                    <div className="p1vera">
                                                        <div className={`pumpVerA ${components.p1.enabled}`} style={{
                                                            width: "3vw", height: "3vh"
                                                        }}>
                                                            {components["p1"] && (
                                                                <PumpGeneral isOn={components["p1"].isOn}
                                                                    key={"p1"}
                                                                    onClick={() => handleComponentIsOnClick("p1")}
                                                                />
                                                            )}
                                                        </div>
                                                        <p className="pumpVerAText">P1</p>

                                                    </div>
                                                </div>
                                                <div className="horizontalVerAModel  p2vera">
                                                    <div className="veratop2">
                                                        <svg
                                                            width="5.2836819mm"
                                                            height="2.4436951mm"
                                                            viewBox="0 0 5.2836819 2.4436951"
                                                            version="1.1"
                                                            id="svg5"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        ><g
                                                            id="layer1"
                                                            transform="translate(-28.458483,-134.60598)"><path
                                                                    cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                                    d="m 33.446355,135.82783 -1.85208,-0.92604 0.46302,0.92604 -0.46302,0.92604 z"
                                                                    fill="#000000"
                                                                    stroke="#000000"
                                                                    strokeMiterlimit="10"
                                                                    pointerEvents="all"
                                                                    id="path325-4-0-5-4-8-5-4-4-3"
                                                                    style={{ strokeWidth: "0.264583" }} /><path
                                                                    cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                                    d="M 32.529682,135.8186 28.460389,135.76"
                                                                    fill="none"
                                                                    stroke="#000000"
                                                                    strokeMiterlimit="10"
                                                                    pointerEvents="stroke"
                                                                    id="path323-6-15-2-0-7-7-8-6-1"
                                                                    style={{ strokeWidth: "0.264583" }} /></g></svg>
                                                    </div>
                                                    <div >
                                                        <div className={`pumpVerA ${components.p2.enabled}`} style={{ width: "3vw", height: "3vh" }}>
                                                            {components["p2"] && (
                                                                <PumpGeneral isOn={components["p2"].isOn}
                                                                    key={"p2"}
                                                                    onClick={() => handleComponentIsOnClick("p2")}
                                                                />
                                                            )}
                                                        </div>
                                                        <p className="pumpVerAText">P2</p>

                                                    </div>
                                                </div>
                                            </div>
                                            <svg
                                                width="57.845116mm"
                                                height="11.856667mm"
                                                viewBox="0 0 57.845116 11.856667"
                                                version="1.1"
                                                id="svg5"
                                                xmlns="http://www.w3.org/2000/svg"
                                            ><g
                                                id="layer1"
                                                transform="translate(-40.529725,-126.73011)"><rect
                                                        style={{ fill: "#000000", fillOpacity: "1", fillRule: "evenodd", stroke: "#000000", strokeWidth: "0.865", strokeDasharray: "none", strokeOpacity: "1", paintOrder: "markers stroke fill" }}
                                                        id="rect14288-4"
                                                        width="2.6120737"
                                                        height="4.2446194"
                                                        x="59.498898"
                                                        y="133.90965" /><path
                                                        cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                        d="m 97.152986,127.02592 -0.92604,1.85208 0.92604,-0.46302 0.92604,0.46302 z"
                                                        fill="#000000"
                                                        stroke="#000000"
                                                        strokeMiterlimit="10"
                                                        pointerEvents="all"
                                                        id="path325-4-0-5-1"
                                                        style={{ strokeWidth: "0.264583" }} /><path
                                                        cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                        d="m 97.175725,128.5303 0.05293,7.50908 c -6.957,0.0242 -26.84302,0.12078 -33.80008,0.11688"
                                                        fill="none"
                                                        stroke="#000000"
                                                        strokeMiterlimit="10"
                                                        pointerEvents="stroke"
                                                        id="path323-6-15-2-0-7-7-4"
                                                        style={{ strokeWidth: "0.264583" }} /><path
                                                        cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                        d="m 57.906451,136.03265 -1.85208,-0.92604 0.46302,0.92604 -0.46302,0.92604 z"
                                                        fill="#000000"
                                                        stroke="#000000"
                                                        strokeMiterlimit="10"
                                                        pointerEvents="all"
                                                        id="path325-4-0-5-4-8-5"
                                                        style={{ strokeWidth: "0.264583" }} /><path
                                                        cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                        d="m 56.644712,135.93782 c -6.957,0.0242 -9.157852,-0.0639 -16.114912,-0.0678"
                                                        fill="none"
                                                        stroke="#000000"
                                                        strokeMiterlimit="10"
                                                        pointerEvents="stroke"
                                                        id="path323-6-15-2-0-7-7-4-7"
                                                        style={{ strokeWidth: "0.264583" }} /><path
                                                        cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                        d="m 50.100628,133.87219 0.09146,-5.82321 -4.069293,-0.0586"
                                                        fill="none"
                                                        stroke="#000000"
                                                        strokeMiterlimit="10"
                                                        pointerEvents="stroke"
                                                        id="path323-6-15-2-0-7-7-8-6"
                                                        style={{ strokeWidth: "0.264583" }} /><path
                                                        cell-id="dD5wv-8hnhxJDom6t97_-17"
                                                        d="m 50.10028,135.06549 0.92604,-1.85208 -0.92604,0.46302 -0.92604,-0.46302 z"
                                                        fill="#000000"
                                                        stroke="#000000"
                                                        strokeMiterlimit="10"
                                                        pointerEvents="all"
                                                        id="path325-4-0-5-4-8-5-4"
                                                        style={{ strokeWidth: "0.264583" }} /></g></svg>


                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div>
                            <label id="state"></label>
                        </div>
                    </div>

                    <div className="videovera">
                        <HLSPlayer src="https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_ts/master.m3u8"></HLSPlayer>
                    </div>
                </div>
                
                <div className="vertikal experiment-form">
                <label htmlFor="descriptionCheckbox" >
                    <input
                        type="checkbox"
                        id="descriptionCheckbox"
                        checked={showDescription}
                        onChange={handleCheckboxChange}
                    />
                    Anlagebeschreibung anzeigen
                </label>
                {showDescription && (
                    <div className="experiment-description">
                        <h2>Anlagebeschreibung</h2>
                        <p>Die Elemente P1 und P2 sind Pumpen. Die Elemente v1, v2 und v3 sind jeweils Ventile.</p>
                    </div>
                    )}
                </div>
            </div>
        }
        </>
    )
}