import React from "react";
import "./Config.css"
import translations from "./../../translations.json"

/**
 * This function returns the Config.
 * It is used to change the configs.
 */
export default function Config({handleChange}) {

    //Sets the language stored in the sessionStorage
    const currentTranslations = translations[sessionStorage.getItem("language")] || {}
    
    /**
     * Handles the config change. It sends the onConfigChange-prop with the event.
     */
    function handleConfigChange(event) {
        sessionStorage.setItem("fontSize", parseInt(event.target.value))
        handleChange()
    }
  
    /**
     * Returns the Config-form
     */
    return (
        <>
            <div className="center">
                <label htmlFor="font-size">{currentTranslations.fontsize}</label>
                <input type="range" id="font-size" name="font-size" min="12" max="32" value={sessionStorage.getItem("fontSize")} onChange={(event) => handleConfigChange(event)} />
            </div>
        </>
    )
}