import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import "./../../AdminFunctionalities.css"
import { getRequestWithToken, putRequestWithToken } from "../../../http-requests/HTTPRequests.js";
import translations from "../../../translations.json"


/**
 * This function returns the ChangeUserDetails.
 * It is used to change the users account-details as an admin.
 */
export default function UserDescription() {

    const currentTranslations = translations[sessionStorage.getItem('language')] || {};


    const [user, setUser] = useState([])
    const [role, setRole] = useState('')

    const { id } = useParams();

    //This method gets called when the component is used. It calls the getUser method containing an id.
    useEffect(() => {
        getUser();
    }, []);

    /**
     * This method gets the user by id. It is needed when the admin wants to change the details of a user.
     * The method send a axios-request to the server and return the user if the request was succefull.
     */
    async function getUser() {
        getRequestWithToken("", `/api/management/users/getuser/userid?userid=${id}`, sessionStorage.getItem("token")).then(response => {
            setUser(response.data)
        }).catch(error => {
            console.log(error);
        })
    }

    //This method gets called when the user selects a role for the student. It sets the role to the selected value.
    const handleChangeRole = (value) => {
        setRole(value);
    }


    /**
     * This method gets called when the admin presses the change role button. The method send a axios-request to the server
     * containing the users id and the new role. If the request was successful it returns the user.
     */
    async function changeRole(id, role) {
        document.getElementById("changeUserDetails").innerHTML = currentTranslations.changeRoleLoading
        putRequestWithToken("", `/api/management/users/updaterole/userid?userid=${id}`, { userid: id, role: role },
            sessionStorage.getItem("token")).then((response) => {
                if (response.status === 200) {
                    document.getElementById("changeUserDetails").innerHTML = currentTranslations.changeRoleSuccess
                    getUser()
            }            
            }
        ).catch(error => {
            document.getElementById("changeUserDetails").innerHTML = currentTranslations.changeRoleFailed
            console.log(error)
        })
    }

    if (sessionStorage.getItem("user")) {

    return (
        <>
            {(sessionStorage.getItem('role') === 'ADMIN' || sessionStorage.getItem('role') === 'ROOT_ADMIN') &&
                <div className="center">
                    <div>
                        <p className="center info">
                            {currentTranslations.adminChangeDetailsP} {user.email}
                        </p>
                        <div>
                            <label>{currentTranslations.adminChangeDetailsId} {user.userid}</label>
                        </div>
                        <div>

                            <label>{currentTranslations.adminChangeDetailsName} {user.email}</label>
                        </div>
                        <div>

                            <label>{currentTranslations.adminChangeDetailsFirstN} {user.firstname}</label>
                        </div>
                        <div>

                            <label>{currentTranslations.adminChangeDetailsLastN} {user.lastname}</label>
                        </div>
                        <div>

                            <label>{currentTranslations.adminChangeDetailsRole} {user.role}</label>
                        </div >
                        <div>

                            <label>{currentTranslations.adminChangeDetailsMail} {user.email}</label>
                        </div >
                        <select defaultValue="" onChange={e => handleChangeRole(e.target.value)} className="selection-menu">
                            <option value="" disabled>{currentTranslations.adminChangeRoleOption}</option>
                            <option value="STUDENT">Student</option>
                            <option value="ADMIN">Admin</option>
                        </select>
                        <button onClick={() => changeRole(user.userid, role)}>{currentTranslations.buttonSubmit}</button>
                    </div>
                    <label id="changeUserDetails"></label>
                </div>}
        </>
        )
    }
    else {
        return (
            <div>{currentTranslations.loginRequired}</div>
        )
    }
}