import "./FAQ.css"

export default function FAQ() {
    //Hilfskontakt
    return (
        <>
            <div className="center">
                Hier kommen fragen hin
            </div>
        </>
    )
}