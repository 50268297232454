import React from 'react';

export default function LanguageSelection() {
    const handleLanguageChange = (event) => {
        const newLanguage = event.target.value;
        sessionStorage.setItem('language', newLanguage);
        window.location.reload();
    };

    return (
        <select value={sessionStorage.getItem("language")} onChange={handleLanguageChange}>
            <option value="en">En</option>
            <option value="de">De</option>
        </select>
    );
};