import React from "react"
import "./DataProtection.css"

/**
 * This function returns the DataProtection
 */
export default function DataProtection() {

    /**
     * Returns DataProtection-form
     */
    return (
        <>
            <p className="center">
                Hi
            </p>
        </>
    )
}