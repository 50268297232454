import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import AnITA from '../anita/AnITA.js';
import VerA from '../vera/VerA.js'
import SimpleAnITA from "../simpleanita/SimpleAnITA";

export default function FacilitiesDescription() {
    const { name, id } = useParams();
    const [available, setAvailable] = useState(false)

    useEffect(() => {
        const facilities = sessionStorage.getItem("facilities")
        if (facilities) {
            if (facilities.includes(name)) {
                setAvailable(true);
            }
        }
    }, [name])

    let FacilityComponent;
    switch (name) {
        case 'AnITA':
            FacilityComponent = AnITA;
            break;
        case 'SimpleAnITA':
            FacilityComponent = SimpleAnITA;
            break;
        case 'VerA':
            FacilityComponent = VerA;
            break;
        default:
            FacilityComponent = null;
            break;
    }


    return (
        <div>
            <FacilityComponent
                available={available}
                facilityName={name}
                experimentId={id}
            />
        </div>
    );

}