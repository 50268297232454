import "./Components.css"

export default function Autoclave({isOn, onClick, fillLevel}) {
    let color = "#D4FFAA"; // grün
    if (fillLevel > "30") {
        color = "#ff8c00"; // orange
    } else if (fillLevel > "70") {
        color = "#ff0000"; // rot
    }
    return (<>
                <div style={{ width: "100%", height: "100%", position: "relative" }}>
        <svg
            version="1.1"
            width="101.00998"
            height="335.00098"
            viewBox="-0.5 -0.5 101.00998 335.00098"
            animation="{&quot;scriptArr&quot;:[],&quot;cellIdArr&quot;:[]}"
            isstartanimationwithpreviouspage="with"
            id="svg377"
                xmlns="http://www.w3.org/2000/svg"
                onClick={onClick}
        >    
            <g
                id="g365"
                transform="translate(-353.04001,-33)">
                <g
                    shape-node=""
                    id="g28">
                    <g
                        shape-node=""
                        id="g16">
                        <path
                            cell-id="dD5wv-8hnhxJDom6t97_-6"
                            d="m 353.04,223.63 c 0,-4.16 9.53,-8 25,-10.07 15.47,-2.08 34.54,-2.08 50.01,0 15.47,2.07 25,5.91 25,10.07 v 131.75 c 0,4.15 -9.53,7.99 -25,10.06 -15.47,2.08 -34.54,2.08 -50.01,0 -15.47,-2.07 -25,-5.91 -25,-10.06 z"
                            fill={color}
                            stroke="#000000"
                            strokeMiterlimit="10"
                            pointerEvents="all"
                                id="path14" />
                        </g>
                        
                    <path
                        cell-id="dD5wv-8hnhxJDom6t97_-5"
                        d="m 424.05,41.36 v 87.11 c 0,4.62 -9.4,8.36 -21,8.36 -11.6,0 -21,-3.74 -21,-8.36 V 41.36 c 0,-4.62 9.4,-8.36 21,-8.36 11.6,0 21,3.74 21,8.36 z"
                            fill={isOn ? "green" : "#ffffff"}
                        stroke="#000000"
                        strokeMiterlimit="10"
                        pointerEvents="all"
                        id="path18" />
                    <rect
                        cell-id="dD5wv-8hnhxJDom6t97_-5"
                        x="399.54999"
                        y="137.17999"
                        width="7"
                        height="200.35001"
                            fill={isOn ? "green" : "#ffffff"}
                        stroke="#000000"
                        pointerEvents="all"
                        id="rect20" />
                    <path
                        cell-id="dD5wv-8hnhxJDom6t97_-5"
                        d="m 382.05,41.36 h 42 m -42,87.11 h 42"
                            fill={isOn ? "green" : "#ffffff"}
                        stroke="#000000"
                        strokeMiterlimit="10"
                        pointerEvents="all"
                        id="path22" />
                    <ellipse
                        cell-id="dD5wv-8hnhxJDom6t97_-5"
                        cx="420.54999"
                        cy="337.53"
                        rx="17.5"
                        ry="3.4842761"
                            fill={isOn ? "green" : "#ffffff"}
                        stroke="#000000"
                        pointerEvents="all"
                        id="ellipse24" />
                    <ellipse
                        cell-id="dD5wv-8hnhxJDom6t97_-5"
                        cx="385.54999"
                        cy="337.53"
                        rx="17.5"
                        ry="3.4842761"
                        fill={isOn ? "green" : "#ffffff"}
                        stroke="#000000"
                        pointerEvents="all"
                        id="ellipse26" />
                </g>
                </g>
                <text
                    x="50%"
                    y="80%"
                    textAnchor="middle"
                    alignmentBaseline="central"
                    fontSize="16px"
                    fontWeight="700"
                    fill="#000000"
                    className="tanktext"
                >
                    Tank: {fillLevel} ml
                </text>
            </svg>
        </div>
    </>
    )
}
