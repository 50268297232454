import React, { useRef, useEffect } from "react";
import Hls from "hls.js";
export default function HLSPlayer({ src }){
    const videoRef = useRef(null);
    useEffect(() => {
        if (videoRef.current) {
            const video = videoRef.current;
            const hls = new Hls();
            hls.loadSource(src);
            hls.attachMedia(video);
        }
    }, [src]);

    return <video ref={videoRef} controls autoPlay width={440} height={260} />;
};