import { createMachine, assign } from "xstate";

export const Vera = ({ valvesAndPumpsState, valves1state, valves2state, valves3state, pumpsOnState, stirrer1State, stirrer2State, stirrer3State, stirrer4State, virtualSensorState, ledState, uvState, contextv1, contextv2, contextv3 }) => {
    
    function evaluateCondition(context) {
        return (((
            context.context1 === "V2OffV3On" || context.context1 === "V2OnV3Off"
        ) && context.context3 === "V1OnV6Off") || ((context.context2 === "V4OffV5On" || context.context2 === "V4OnV5Off") && context.context3 === "V1OffV6On")
        )
    }
    return createMachine({
        id: 'VerA',
        type: 'parallel',
        context: {
            context1: contextv1,
            context2: contextv2,
            context3: contextv3
        },
        states: {
            "ValvesAndPumps": {
                id: "ValvesAndPumps",
                initial: valvesAndPumpsState,
                states: {
                    "PumpsOff": {
                        id: "PumpsOff",
                        type: "parallel",
                        on: {
                            p1on: {
                                target: 'PumpsOn.P1OnP2Off',
                                cond: (context) => evaluateCondition(context)
                            },
                            p2on: {
                                target: 'PumpsOn.P1OffP2On',
                                cond: (context) => evaluateCondition(context)
                            }
                        },
                        states: {
                            "Valves1": {
                                id: "Valves1",
                                initial: valves1state,
                                states: {
                                    "V2OnV3Off": {
                                        id: "V2OnV3Off",
                                        on: {
                                            v2off: {
                                                target: 'V2OffV3Off',
                                                actions: assign({ context1: () => "V2OffV3Off" })
                                            },
                                            v3on: {
                                                target: 'V2OnV3On',
                                                actions: assign({ context1: () => "V2OnV3On" })
                                            }
                                        }
                                    },
                                    "V2OffV3Off": {
                                        id: "V2OffV3Off",
                                        on: {
                                            v2on: {
                                                target: "V2OnV3Off",
                                                actions: assign({ context1: () => "V2OnV3Off" })
                                            },
                                            v3on: {
                                                target: "V2OffV3On",
                                                actions: assign({ context1: () => "V2OffV3On" })
                                            }
                                        }
                                    },
                                    "V2OffV3On": {
                                        id: "V2OffV3On",
                                        on: {
                                            v3off: {
                                                target: "V2OffV3Off",
                                                actions: assign({ context1: () => "V2OffV3Off" })
                                            },
                                            v2on: {
                                                target: "V2OnV3On",
                                                actions: assign({ context1: () => "V2OnV3On" })
                                            }
                                        }
                                    },
                                    "V2OnV3On": {
                                        id: "V2OnV3On",
                                        on: {
                                            v3off: {
                                                target: "V2OnV3Off",
                                                actions: assign({ context1: () => "V2OnV3Off" })
                                            },
                                            v2off: {
                                                target: "V2OffV3On",
                                                actions: assign({ context1: () => "V2OffV3On" })
                                            }
                                        }
                                    },
                                    
                                }
                            },
                            "Valves2": {
                                id: "Valves2",
                                initial: valves2state,
                                states: {
                                    "V4OnV5Off": {
                                        id: "V4OnV5Off",
                                        on: {
                                            v4off: {
                                                target: "V4OffV5Off",
                                                actions: assign({ context2: () =>  "V4OffV5Off" })
                                            },
                                            v5on: {
                                                target: "V4OnV5On",
                                                actions: assign({ context2: () =>  "V4OnV5On" })
                                            }
                                        }
                                    },
                                    "V4OffV5Off": {
                                        id: "V4OffV5Off",

                                        on: {
                                            v4on: {
                                                target: "V4OnV5Off",
                                                actions: assign({ context2: () =>  "V4OnV5Off" })
                                            },
                                            v5on: {
                                                target: "V4OffV5On",
                                                actions: assign({ context2: () =>  "V4OffV5On" })
                                            }
                                        }
                                    },
                                    "V4OffV5On": {
                                        id: "V4OffV5On",
                                        on: {
                                            v5off: {
                                                target: "V4OffV5Off",
                                                actions: assign({ context2: () => "V4OffV5Off" })
                                            },
                                            v4on: {
                                                target: "V4OnV5On",
                                                actions: assign({ context2: () =>  "V4OnV5On" })
                                            }
                                        }
                                    },
                                    "V4OnV5On": {
                                        id: "V4OnV5On",
                                        on: {
                                            v5off: {
                                                target: "V4OnV5Off",
                                                actions: assign({ context2: () => "V4OnV5Off" })
                                            },
                                            v4off: {
                                                target: "V4OffV5On",
                                                actions: assign({ context2: () =>  "V4OffV5On" })
                                            }
                                        }
                                    },
                               
                                }
                            },
                            "Valves3": {
                                id: "Valves3",
                                initial: valves3state,
                                states: {
                                    "V1OnV6Off": {
                                        id: "V1OnV6Off",
                                        on: {
                                            v1off: {
                                                target: "V1OffV6Off",
                                                actions: assign({ context3: () => "V1OffV6Off" })
                                            },
                                            v6on: {
                                                target: "V1OnV6On",
                                                actions: assign({ context3: () =>  "V1OnV6On" })
                                            }
                                        }
                                    },
                                    "V1OffV6Off": {
                                        id: "V1OffV6Off",
                                        on: {
                                            v1on: {
                                                target: "V1OnV6Off",
                                                actions: assign({ context3: () => "V1OnV6Off" })
                                            },
                                            v6on: {
                                                target: "V1OffV6On",
                                                actions: assign({ context3: () =>"V1OffV6On" })
                                            }
                                        }
                                    },
                                    "V1OffV6On": {
                                        id: "V1OffV6On",
                                        on: {
                                            v6off: {
                                                target: "V1OffV6Off",
                                                actions: assign({ context3: () => "V1OffV6Off" })
                                            },
                                            v1on: {
                                                target: "V1OnV6On",
                                                actions: assign({ context3: () => "V1OnV6On" })
                                            }
                                        }
                                    },
                                    "V1OnV6On": {
                                        id: "V1OnV6On",
                                        on: {
                                            v6off: {
                                                target: "V1OnV6Off",
                                                actions: assign({ context3: () => "V1OnV6Off" })
                                            },
                                            v1off: {
                                                target: "V1OffV6On",
                                                actions: assign({
                                                    context3: () => "V1OffV6On"
                                                })
                                                
                                            }
                                        }
                                    }
                                }
                            },hist: {
                                type: "history",
                                history: "deep"
                            }
                        },
                        
                    },
                    "PumpsOn": {
                        id: "PumpsOn",
                        initial: pumpsOnState,
                        states: {
                            "P1OffP2On": {
                                id: "P1OffP2On",
                                on: {
                                    p2off: {
                                        target: "#VerA.ValvesAndPumps.PumpsOff.hist"                                    },
                                    p1on: {
                                        target: "P1OnP2On",
                                    }
                                }
                            },
                            "P1OnP2On": {
                                id: "P1OnP2On",
                                on: {
                                    p1off: {
                                        target:"P1OffP2On"
                                    },
                                    p2off: {
                                        target:"P1OnP2Off"
                                    }
                                }
                            },
                            "P1OnP2Off": {
                                id: "P1OnP2Off",
                                on: {
                                    p1off: {
                                        target: "#VerA.ValvesAndPumps.PumpsOff.hist"
                                    },
                                    p2on: {
                                        target: "P1OnP2On"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "Stirrer": {
                id: "Stirrer",
                type: "parallel",
                states: {
                    "Stirrer1": {
                        id: "Stirrer1",
                        initial: stirrer1State,
                        states: {
                            "S1Off": {
                                id: "S1Off",
                                on: {
                                    s1on: {
                                        target:"S1On"
                                    }
                                }
                            },
                            "S1On": {
                                id: "S1On",
                                on: {
                                    s1off: {
                                        target:"S1Off"
                                    }
                                }
                            }
                        }
                    },
                    "Stirrer2": {
                        id: "Stirrer2",
                        initial: stirrer2State,
                        states: {
                            "S2Off": {
                                id: "S2Off",
                                on: {
                                    s2on: {
                                        target: "S2On"
                                    }
                                }
                            },
                            "S2On": {
                                id: "S2On",
                                on: {
                                    s2off: {
                                        target: "S2Off"
                                    }
                                }
                            }
                        }
                    },
                    "Stirrer3": {
                        id: "Stirrer3",
                        initial: stirrer3State,
                        states: {
                            "S3Off": {
                                id: "S3Off",
                                on: {
                                    s3on: {
                                        target: "S3On"
                                    }
                                }
                            },
                            "S3On": {
                                id: "S3On",
                                on: {
                                    s3off: {
                                        target: "S3Off"
                                    }
                                }
                            }
                        }
                    },
                    "Stirrer4": {
                        id: "Stirrer4",
                        initial: stirrer4State,
                        states: {
                            "S4Off": {
                                id: "S4Off",
                                on: {
                                    s4on: {
                                        target: "S4On"
                                    }
                                }
                            },
                            "S4On": {
                                id: "S4On",
                                on: {
                                    s4off: {
                                        target: "S4Off"
                                    }
                                }
                            }
                        }
                    },
                }
            },
            "Lights": {
                id: "Lights",
                type: "parallel",
                states: {
                    "LED": {
                        id: "LED",
                        initial: ledState,
                        states: {
                            "LEDOff": {
                                id: "LEDOff",
                                on: {
                                    ledon: {
                                        target:"LEDOn"
                                    }
                                }
                            },
                            "LEDOn": {
                                id: "LEDOn",
                                on: {
                                    ledoff: {
                                        target:"LEDOff"
                                    }
                                }
                            }
                        }
                    },
                    "UV": {
                        id: "UV",
                        initial: uvState,
                        states: {
                            "UVOff": {
                                id: "UVOff",
                                on: {
                                    uvon: {
                                        target: "UVOn"
                                    }
                                }
                            },
                            "UVOn": {
                                id: "UVOn",
                                on: {
                                    uvoff: {
                                        target:"UVOff"
                                    }
                                }
                            }
                        }
                    }
                }
            },
            "VirtualSensor": {
                id: "VirtualSensor",
                initial: virtualSensorState,
                states: {
                    "TankFull": {
                        id: "TankFull",
                        on: {
                            t1empty: {
                                target:"TankEmpty"
                            }
                        }
                    },
                    "TankAlmostEmpty": {
                        id: "TankAlmostEmpty",
                        on: {
                            t1empty: {
                                target:"TankEmpty"
                            }
                        }
                    },
                    "TankEmpty": {
                        id:"TankEmpty"
                    }
                },
                on: {
                    t1full: {
                        target:"VirtualSensor.TankFull"
                    }
                }
            }
        }
        , predictableActionArguments: true
    })

}