import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
    export default function Chart({value}){
    const [data, setData] = useState([{ time: 0, value: value }]);
    useEffect(() => {
        const intervalId = setInterval(() => {
            setData(prevData => {
                const newValue = value;
                const newTime = prevData[prevData.length - 1].time + 1;
                return [...prevData, { time: newTime, value: newValue }];
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, [value])

    return (
        <LineChart width={350} height={200} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="value" stroke="#8884d8" />
        </LineChart>
    )
}