import React, { useEffect } from "react";
import { useState } from "react";
import translations from "../../../translations.json"
import { Link } from "react-router-dom"
import { deleteRequestWithToken, getRequestWithToken } from "../../../http-requests/HTTPRequests.js";


/**
 * This function returns the UserManagement.
 * It is used to display the users for the admin and managing them.
 */
export default function UserOverview() {

    const currentTranslations = translations[sessionStorage.getItem('language')] || {};

    const [users, setUsers] = useState([])
    const [searchQuery, setSearchQuery] = useState('');

    //Filters the users
    const filteredUsers = (searchQuery !== "") ? users.filter(user => {
        return user.email.toLowerCase().includes(searchQuery.toLowerCase())
    }) : users;

    //This method gets called when the component is used. It calls the getUsers method.
    useEffect(() => {
        getUsers();
    }, []);

    /**
     * This method gets the users. It send a axios-request to the server. If the request was successful, it sets the users.
     */
    async function getUsers() {
        getRequestWithToken("", '/api/management/users', sessionStorage.getItem("token"))
            .then(response => {
            console.log(response.data)
            setUsers(response.data)
        }).catch(error => {
            console.log(error);
        })
    }

     /**
      * This method deletes a user by his id. It sends a axios-request to the server. If the request was successful it gets the users again.
      */
    async function deleteUser(id) {
        // document.getElementById("userLabel").innerHTML = currentTranslations.adminDeletingUser
        deleteRequestWithToken("", `/api/management/users/deleteuser/userid?userid=${id}`, sessionStorage.getItem("token"))
        .then(response => {
                if(response.status === 200) {
                    // document.getElementById('userLabel').innerHTML = currentTranslations.deleteUserSuccess
                    getUsers()
                } else {
                    // document.getElementById('userLabel').innerHTML =  currentTranslations.deleteUserFail
                }
            }).catch(error => {
                console.log(error)
                // document.getElementById("userLabel").innerHTML = currentTranslations.adminDeletingUserFailed
            })
        }
        
//Liste scrollable machen und height fest definieren
    if (sessionStorage.getItem("user")) {
        return (
            <>
                {(sessionStorage.getItem('role') === 'ADMIN' || sessionStorage.getItem('role') === 'ROOT_ADMIN' )&&
                    <div className="center">
                        <div className="info">
                            <input type="text" placeholder="Search" value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}>
                            </input>
                        </div>
                        <table className="admintableuser">
                            <thead>
                                <tr className="settradmin">
                                    <td className="settabledataadmin"></td>
                                <td className="settabledataadmin name">E-Mail</td>
                                <td className="settabledataadmin name">{currentTranslations.firstname}</td>
                                    <td className="settabledataadmin name">{currentTranslations.lastname}</td>
                                    <td className="settabledataadmin name">{currentTranslations.role}</td>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredUsers.map(user => (
                                    <tr key={user.userid} className="settradmin">
                                        <td className="settabledataadmin"><button onClick={() => deleteUser(user.userid)}>{currentTranslations.buttonDelete}</button></td>
                                        <td className="settabledataadmin">
                                            <Link to={`/ChangeUserDetails/${user.userid}`}>{user.email}</Link></td>
                                        <td className="settabledataadmin">{user.firstname}</td>
                                        <td className="settabledataadmin">{user.lastname}</td>
                                        <td className="settabledataadmin">{user.role}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <label id="deleteUserLabel"></label>
                    </div>}
            </>
        )
    }
    else {
        return (
            <div>{currentTranslations.loginRequired }</div>
        )
    }

}


