import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import "./FacilitiesOverview.css"
import ThumbnailAnITA from "./../../../images/ThumbnailAnITA.jpeg"
import ThumbnailVerA from "./../../../images/ThumbnailVerA.jpeg"
import { getRequestWithoutToken } from "../../../http-requests/HTTPRequests.js"
import translations from "../../../translations.json"

export default function FacilitiesOverview() {

    const [facilities, setFacilities] = useState([]);
    const [exfacilities, setExFacilities] = useState([]);
    const currentTranslations = translations[sessionStorage.getItem("language")] || {}

    const facilitiesThumbnails = {
        "AnITA": ThumbnailAnITA,
        "SimpleAnITA": ThumbnailAnITA,
        "VerA": ThumbnailVerA
    }

    useEffect(() => {
        getFacilities()
    }, []);

    async function getFacilities() {
        getRequestWithoutToken("", "/api/management/facilities")
        .then(response => {
            setFacilities(response.data)
            console.log(response.data)
        }).catch(error => {
            console.log(error);
        })
    }

    function getFacilityThumbnail(facilityName) {
        return facilitiesThumbnails[facilityName] || ""
    }

    //todo: extern facilities
    return (
        <div className="center">
            <h1 className="info">{currentTranslations.experimentFacilities}</h1>
            <ul>
                {facilities.map(facility => (
                    <li key={facility.registeredFacilityId}>
                        <div className="list-item">
                            <img src={getFacilityThumbnail(facility.registeredFacilityName)} alt={facility.registeredFacilityName} />
                            <div className="text">
                                <h2 className="name"><Link to={`facility/${facility.registeredFacilityName}/${facility.registeredFacilityId}`}>{facility.registeredFacilityName}</Link></h2>
                                <p>{facility.registeredFacilityDescription}</p>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );

}