import VeraDark from "./../../../images/FacilityDarkVerA.jpeg"
import "./../description/FacilitiesDescription.css"
import ExpCalendar from "../../../appointment-management/AppointmentManagement.js"
import { postRequestWithToken, getRequestWithToken } from "../../../http-requests/HTTPRequests.js"
import { useState, useEffect } from "react"
import translations from "../../../translations.json"


/**
 * This function returns the VerA.
 * It is used to display the VerA details.
 */
export default function VerA({ available, facilityName, experimentId }) {
    //Sets the language stored in the sessionStorage
    const currentTranslations = translations[sessionStorage.getItem("language")] || {}

    //Sets the duration for the experiment facility
    const [duration, setDuration] = useState(60000)
    const [facility, setFacility] = useState([])

    /**
     * This hook gets called when the component is mounted.
     * It calls the getFacility-method.
     */
    useEffect(() => {
        getFacility();
    }, [])

    /**
      * This function calls the getRequestWithToken-method for the passed experimentId.
      */
    async function getFacility() {
        getRequestWithToken("", `/api/management/facilities/getfacility/facilityid?facilityid=${experimentId}`, sessionStorage.getItem("token")).
            then(response => {
                getRequestWithToken(response.data.institution.institutionURL, `/api/scheduling/facilities/getconnectedfacility/connectedfacilityname?connectedfacilityname=${response.data.registeredFacilityName}`, sessionStorage.getItem("token")).then(response2 => {
                    setFacility(response2.data)
                    setDuration(response2.data.maxAppointmentDuration)
                })
            }
            ).catch(error => {
                console.log(error)
            })
    }

    async function sendFacilityRequest() {
        document.getElementById("veraLabel").innerHTML = currentTranslations.sendingRequest
        postRequestWithToken("", "/api/management/requests/facilities/dorequest",
            { userRequestName: "Facilities", userRequestValue: [facilityName], username: sessionStorage.getItem("user"), role: sessionStorage.getItem("role"), email: sessionStorage.getItem("user") },
            sessionStorage.getItem("token")
        )
            .then(response => {
                if (response.status === 200) {
                    document.getElementById("veraLabel").innerHTML = currentTranslations.sendingRequestSuc
                }

            }).catch(error => {
                document.getElementById("veraLabel").innerHTML = currentTranslations.sendingRequestFailed
                console.log(error)
            })
    }



    return (<>
        <div className="center" data-testid="test-component">
            <h1 data-testid="VerA-Title">VerA</h1>
            <div className="horizontal">
                <img src={VeraDark} alt="VerA im dunklen Modus" className="imageVerA" data-testid="VerA-img"></img>
            <p className="info textVerA" data-testid="VerA-Desc">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
            </p>
                
            </div>
            {facility && facility.connectedFacilityInstitutionURL && available ?
                <div data-testid="exp-calendar">
                    <ExpCalendar available={available} facilityName={facilityName} connectedId={facility.connectedFacilityId} duration={duration} ecs={facility.connectedFacilityInstitutionURL}></ExpCalendar>
                </div> : <div>
                    <p>{currentTranslations.sendFacilityRequest}</p>
                    <button onClick={() => sendFacilityRequest()} data-testid="Request-button">{currentTranslations.send}</button>
                </div>}
            <label  id="veraLabel"></label>
        </div>
    </>
    )
}