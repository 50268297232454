import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import "./../../AdminFunctionalities.css"
import { getRequestWithToken, postRequestWithToken, putRequestWithToken } from "../../../http-requests/HTTPRequests.js";
import translations from "../../../translations.json"


/**
 * This function returns the ChangeInstitutionDetails.
 * It is used to change the institution-detailsn.
 */
export default function InstitutionDescription({changeInstitutionSuccess}) {

    const currentTranslations = translations[sessionStorage.getItem('language')] || {};

    const [institution, setInstitution] = useState([])
    const [institution_name, setInstitutionName] = useState('')
    const [institution_url, setInstitutionURL] = useState('');
    const [experiment_facility_name, setExperimentFacilityName] = useState('')
    const [experiment_facility_description, setExperimentFacilityDescription] = useState('');
        const [extern_public_key, setExternPublicKey] = useState('')
    const { id } = useParams();
    const [newInstName, setNewInstName] = useState("")
    const [newInstURL, setNewInstURL] = useState("")

    //This method gets called when the component is used. It calls the getUser method containing an id.
    useEffect(() => {
        getInstitution();
    }, []);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        if (id === "institution_name") {
            setInstitutionName(value);
        }
        if (id === "institution_url") {
            setInstitutionURL(value);
        }
        if (id === "experiment_facility_name") {
            setExperimentFacilityName(value)
        }
        if (id === "experiment_facility_description") {
            setExperimentFacilityDescription(value)
        }
        if (id === "extern_public_key") {
            setExternPublicKey(value)
        }
    }


    /**
     * This method adds a facility to an institution. It gets the facility name, description, url, institutionId and institution name as parameters.
     * It sends a axios-request to the server. If the request was successful, it gets the institutions.
     */
    async function addFacility() {
        const addFacilityInstitution = {
            institutionId: institution.institutionId,
            institutionName: institution.institutionName,
            institutionURL: institution.institutionURL,
            registeredFacilitiesNames: institution.registeredFacilitiesNames
        }
	
        postRequestWithToken("", "/api/management/facilities", { registeredFacilityName: experiment_facility_name, registeredFacilityDescription: experiment_facility_description, registeredFacilityURL: "", institution: addFacilityInstitution },
            sessionStorage.getItem("token")).then(
                response => {
                    if (response.status === 201) {
                        if (id === "1") {
                            const dataForConnectedFacility = {
                                connectedFacilityName: experiment_facility_name,
                                connectedFacilityDescription: experiment_facility_description,
                                connectedFacilityInstitutionURL: institution.institutionURL,
                                connectedFacilityURL: "",
                                maxAppointmentDuration: "6000",
                                connectedFacilityBrokerTopic: `/topic/${experiment_facility_name}`
                            }
                            postRequestWithToken("", "/api/scheduling/facilities", dataForConnectedFacility, sessionStorage.getItem("token")).then(
                                response2 => {
                                    if (response2.status === 201) {
                                        // document.getElementById("institutionInfo").innerHTML = currentTranslations.succaddedexp
                                    }
                                }
                                //Todo: Label ausgabe
                            )
                        }
                        //Todo: Label ausgabe

                    }
                }
            ).catch(error => {
                // document.getElementById("institutionInfo").innerHTML = currentTranslations.adminFuncAddFacilityFailed
                console.log(error)
            })
    }

    async function generateKeyPair() {
        putRequestWithToken("", "/api/management/institutions/key/updateKeyPair", { institutionName: institution.institutionName, institutionURL: institution.institutionURL, registeredFacilitiesNames: institution.registeredFacilitiesNames, totalBookedTime: institution.totalBookedTime },
            sessionStorage.getItem("token")).then(
                response => {
                    console.log(response)
                    changeInstitutionSuccess()
                }
            ).catch(error => {
                // document.getElementById("institutionInfo").innerHTML = currentTranslations.adminFuncAddFacilityFailed
                console.log(error)
            })
    }

    async function updatePublicKey(institutionName) {
        putRequestWithToken("", "/api/management/institutions/key/setPublicKey", { institutionName: institutionName, publicKey: extern_public_key }, sessionStorage.getItem("token")).then(
            response => {
                console.log(response)
                getInstitution()
            }
        ).catch(error => {
            // document.getElementById("institutionInfo").innerHTML = currentTranslations.adminFuncAddFacilityFailed
            console.log(error)
        })
    }

    /**
     * This method changes the name of an institution. It sends a axios-request to the server.
     * If the request was successful, it gets the institutions.
     */
    async function changeInstitutionDetails() {
        // document.getElementById("changeInstitutionDetails").innerHTML = currentTranslations.adminFuncChangeInstNameLoad
        //Checks if the institution input is null
        if (institution_name === "" && institution_url === "") {
            // document.getElementById("changeInstitutionDetails").innerHTML = currentTranslations.adminFuncChangeInstNotNull
            return;
        }

        const updatedDetails = {
            institutionName: institution_name === "" ? institution.institutionName : institution_name,
            institutionURL: institution_url === "" ? institution.institutionURL : institution_url,
            registeredFacilitiesNames: institution.registeredFacilitiesNames,
            publicKey: institution.publicKey,
            privateKey: institution.privateKey,
            institutionId: institution.institutionId
        };

        putRequestWithToken("", `/api/management/institutions/updateinstitution/institutionid?institutionid=${institution.institutionId}`, updatedDetails, sessionStorage.getItem("token")).then(
            response => {
                console.log(response.status)
                if (response.status === 200) {
                    console.log("hier")
                    // document.getElementById("institutionInfo").innerHTML = currentTranslations.adminFuncChangedInstName
                    console.log(institution.institutionId)
                    if (institution.institutionId === 1) {
                        changeInstitutionSuccess()
                    }
                }
            }
        ).catch(error => {
            // document.getElementById("institutionInfo").innerHTML = currentTranslations.adminFuncChangeInstNameFailed
            console.log(error)
        })
    }

    /**
     * This method gets the user by id. It is needed when the admin wants to change the details of a user.
     * The method send a axios-request to the server and return the user if the request was succefull.
     */
    async function getInstitution() {
        getRequestWithToken("", `/api/management/institutions/getinstitution/institutionid?institutionid=${id}`, sessionStorage.getItem("token")).then(response => {
            setInstitution(response.data)
            console.log(JSON.stringify(response.data.institutionName) + " inst data")
            setNewInstName(response.data.institutionName)
            setNewInstURL(response.data.institutionURL)
        }).catch(error => {
            console.log(error);
        })
    }

    //update key extern, add facility

    if (sessionStorage.getItem("user")) {

        return (
            <>
                {(sessionStorage.getItem('role') === 'ADMIN' || sessionStorage.getItem('role') === 'ROOT_ADMIN') &&
                    <div className="center">
                        <div>
                            <p className="center info">
                                {institution.institutionName}
                            </p>
                            {id === "1" && <div >
                                <label >{currentTranslations.adminChangeInstitutionPrivateKey}<p className="institutionKeys">{institution.privateKey}</p></label>
                            </div>
                            }
                            <div>
                                <label >{currentTranslations.adminChangeInstitutionPublicKey}<p className="institutionKeys">{institution.publicKey}</p></label>
                            </div>
                            {id === "1" && <div><button onClick={() => generateKeyPair()}>Generate key pair</button></div>}
                            <div>
                                <input type="text"
                                    placeholder={currentTranslations.changeInstitutionNamePlaceholder} id="institution_name" onChange={(e) => handleInputChange(e)} value={institution_name}></input>
                                <input type="text"
                                    placeholder={currentTranslations.changeInstitutionURLPlaceholder} id="institution_url" onChange={(e) => handleInputChange(e)} value={institution_url}></input>
                                <button onClick={() => changeInstitutionDetails()}>Change</button>
                            </div>
                            <div>
                                <div>
                                    <label>
                                        Facility Name
                                    </label>
                                    <input type="text"
                                        placeholder="Facility name..." id="experiment_facility_name" onChange={(e) => handleInputChange(e)} value={experiment_facility_name}></input>
                                </div>
                                <div>
                                    <label>
                                        Facility Desc
                                    </label>
                                    <input type="text"
                                        placeholder="Facility desc..." id="experiment_facility_description" onChange={(e) => handleInputChange(e)} value={experiment_facility_description}></input>
                                </div>
                                <button onClick={() => addFacility()}>Add Facility</button>
                            </div>
                            {id !== "1" && <div>
                                <input type="text"
                                    placeholder="Public key..." id="extern_public_key" onChange={(e) => handleInputChange(e)} value={extern_public_key}></input>
                                <button onClick={() => updatePublicKey()}>Change public key</button>
                                </div>}
                        </div>
                        <label id="changeInstitutionDetails"></label>
                    </div>}
            </>
        )
    }
    else {
        return (
            <div>{currentTranslations.loginRequired}</div>
        )
    }
}