import "./Components.css"

export default function ThreeWayGateValve({ isOpen, onClick }) {
    return (
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
        <svg
            width="6.9991417mm"
            height="5.4986382mm"
            viewBox="0 0 6.9991417 5.4986382"
            animation="{&quot;scriptArr&quot;:[],&quot;cellIdArr&quot;:[]}"
            isstartanimationwithpreviouspage="with"
            version="1.1"
            id="svg4801"
            xmlns="http://www.w3.org/2000/svg"
            onClick={onClick}
        >
            <g
                id="layer1"
                transform="translate(-63.499296,-60.001215)"
            >
                <path
                    style={{
                        fill: isOpen ? "#00ff00" : "#ffffff",
                        stroke: "#000000",
                        strokeWidth: "0.264583",
                        strokeOpacity: "1"
                    }}
                    id="path4936-8"
                    d="m 53.022918,60.009045 -1.477835,0.853228 -1.477835,0.853228 v -1.706456 -1.706457 l 1.477835,0.853228 z"
                    transform="matrix(-1.0439382,0,0,-1.0333683,122.62745,124.01284)"
                />
                <path
                    style={{
                        fill: isOpen ? "#ffffff" : "#00ff00",
                        stroke: "#000000",
                        strokeWidth: "0.264583",
                        strokeOpacity: "1"
                    }}
                    id="path4936-8-1"
                    d="m 53.022918,60.009045 -1.477835,0.853228 -1.477835,0.853228 v -1.706456 -1.706457 l 1.477835,0.853228 z"
                    transform="matrix(1.0438895,0,0,-1.0333204,11.37272,124.00987)"
                />
                <path
                    style={{
                        fill: "#00ff00",
                        stroke: "#000000",
                        strokeWidth: "0.264583",
                        strokeOpacity: "1"
                    }}
                    id="path4936-8-4"
                    d="m 53.022918,60.009045 -1.477835,0.853228 -1.477835,0.853228 v -1.706456 -1.706457 l 1.477835,0.853228 z"
                    transform="matrix(0,-1.0439382,-1.0333683,0,129.01162,117.62886)"
                />
            </g>
            <g
                        id="layer2"
                transform="translate(-63.499296,-60.001215)"
            >
                <path
                    style={{
                        fill: "#000000",
                        fillOpacity: "1",
                        stroke: "#000000",
                        strokeWidth: "0.264988",
                        strokeOpacity: "1"
                    }}
                    id="path5908"
                    d="m 76.959232,65.607483 a 2.8674347,6.1350121 0 0 1 -2.864402,6.135009 2.8674347,6.1350121 0 0 1 -2.870461,-6.12203 l 2.867428,-0.01298 z"
                />
                <path
                    style={{ fill: "none",
                        fillRule: "evenodd",
                        stroke:"#000000",
                        strokeWidth: "0.0992121px",
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity:"1"}}
                    d="m 74.105606,69.038334 7.87e-4,-1.099929"
                    id="path5979-0-6-1"
                />
                <path
                    style={{
                        fill: "none",
                        fillRule: "evenodd",
                        stroke: "#000000",
                        strokeWidth: "0.0929589px",
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity:"1"}}
                    d="m 74.117079,67.967242 -0.93421,-0.934379"
                    id="path5979-0-6"
                />
                <path
                    style={{
                        fill: "none",
                        fillRule: "evenodd",
                        stroke: "#000000",
                        strokeWidth: "0.0929589px",
                        strokeLinecap: "butt",
                        strokeLinejoin: "miter",
                        strokeOpacity:"1"}}
                    d="m 74.082869,67.967242 0.93421,-0.934379"
                    id="path5979-0-6-9"
                />
            </g>
            </svg></div>

    )
}
