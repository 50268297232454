import "./Components.css";

export default function PumpGeneral({ isOn, onClick }) {
    return (
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <svg
                version="1.1"
                width="100%"
                height="100%"
                viewBox="-0.5 -0.5 37.207108 37"
                animation="{&quot;scriptArr&quot;:[],&quot;cellIdArr&quot;:[]}"
                isstartanimationwithpreviouspage="with"
                id="svg4997"
                xmlns="http://www.w3.org/2000/svg"
                onClick={onClick}
                style={{ position: "absolute", top: 0, left: 0 }}>
                <g id="g4985" transform="translate(-136,-186)">
                    <g shape-node="" id="g4720">
                        <circle
                            cell-id="dD5wv-8hnhxJDom6t97_-18"
                            cx="154"
                            cy="204"
                            fill={isOn ? "green" : "#ffffff"}
                            stroke="#000000"
                            pointerEvents="all"
                            id="ellipse4716"
                            r="18"
                        />
                        <path
                            cell-id="dD5wv-8hnhxJDom6t97_-18"
                            d="m 154,186 18,18 -18,18"
                            fill="none"
                            stroke="#000000"
                            strokeMiterlimit="10"
                            pointerEvents="all"
                            id="path4718"
                        />
                    </g>
                </g>
            </svg>
        </div>
    );
}
