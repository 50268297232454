import React from "react";
import "./AdminFunctionalities.css"
import translations from "./../translations.json"
import { Link } from "react-router-dom";

/**
 * This function return the AdminFunctionalities. The admin functionalities contains the user-management and the institutions.
 */
export default function AdminFunctionalities() {


    const currentTranslations = translations[sessionStorage.getItem("language")] || {}
  if (sessionStorage.getItem("user")) {

    return (
        <>
            {(sessionStorage.getItem('role') === "ADMIN" || sessionStorage.getItem('role') === "ROOT_ADMIN" )&& <div className="center">
          <p>{currentTranslations.chooseAdminFunc}</p>
        <ul className="admin-list">
          <li className="admin-list-item">
            <Link to="/admin_functionalities/institutions" className="admin-list-link">
              {currentTranslations.adminFuncInstitutions}
            </Link>
          </li>
          <li className="admin-list-item">
            <Link to="/admin_functionalities/user_management" className="admin-list-link">
              {currentTranslations.adminFuncUsermanagement}
            </Link>
          </li>
          <li className="admin-list-item">
            <Link to="/admin_functionalities/requests" className="admin-list-link">
                {currentTranslations.adminFuncRequests}
            </Link>
          </li>
        </ul>
    </div>
            }
        </>
  )
}
    else {
  return (
    <div>{currentTranslations.loginRequired}</div>
  )
}
}