import {createMachine} from "xstate";

export const SimpleAnITAMachine = ({ modestate, actuatorsstate, glass_reactorstate, stirrerstate, eatankstate, lightstate }) => {


    function evaluateConditionPeaOn(context,event,{cond,state}) {
        const eatank = state.value["EA Tank"];
        const gr = state.value["Mode"]["Experiment"]["Glass Reactor"];
        if (eatank!=="EA Empty" && (gr==="Enough Water" || gr ==="Water > 2xEA")) {
            return true;
        }
        return false;
        /*(
            (context.contextGR === "Enough Water" || context.contextGR === "Water > 2xEA") && context.contextEA !== "EA Empty"
        );*/
    }

    function evaluateConditionPwOn(context,event,{cond,state}) {
        const gr = state.value["Mode"]["Experiment"]["Glass Reactor"];
        if (gr !== "Unknown" && gr !== "Full") {
            return true;
        }
        return false;
        /*(
            (context.contextGR !== "Unknown" && context.contextGR !== "Full")
        )*/
    }

    return createMachine({
        id: 'SimpleAnITA',
        type: 'parallel',
        states: {
            "Mode": {
                id: "Mode",
                initial: modestate,
                states: {
                    "Initialising": {
                        id: "0",
                        on: {
                            initdone: {
                                target: "Draining"
                            },
                            initdraindone: {
                                target: "Idling"
                            }
                        }
                    },
                    "Draining": {
                        id: "1",
                        on: {
                            initdraindone: {
                                target: "Idling"
                            }
                        }
                    },

                    "Idling": {
                        id: "2",
                        on: {
                            getready: {
                                target: "Filling Tubes"
                            }
                        }
                    },

                    "Filling Tubes": {
                        id: "3",
                        on: {
                            eatubefull: {
                                target: "WT Filling"
                            }
                        }
                    },

                    "WT Filling": {
                        id: "4",
                        on: {
                            wtubefull: {
                                target: "After Fill Drain"
                            },
                            //todo: remove and fix in virt
                            drained: {
                                target: "Experiment"
                            }
                        }
                    },

                    "After Fill Drain": {
                        id: "5",
                        on: {
                            drained: {
                                target: "Experiment"
                            }
                        }
                    },

                    "Experiment": {
                        id: "6",
                        type: 'parallel',
                        on: {
                            backtoinit: {
                                target: "Initialising",
                            }
                        },
                        states: {
                            "Actuators": {
                                initial: actuatorsstate,
                                states: {
                                    "pwoff peaoff vwclose veaclose vdclose": {
                                        id: "0",
                                        on: {
                                            vdopen: {
                                                target: "pwoff peaoff vwclose veaclose vdopen",
                                            },
                                            veaopen: { target: "pwoff peaoff vwclose veaopen vdclose" },
                                            peaon: { target: "pwoff peaoff vwclose veaopen vdclose" },
                                            vwopen: { target: "pwoff peaoff vwopen veaclose vdclose" },
                                            pwon: { target: "pwoff peaoff vwopen veaclose vdclose" }
                                        }
                                    },
                                    // "pwoff peaoff vwclosed veaclosed vdclosed"
                                    "pwoff peaoff vwclose veaclose vdopen": {
                                        id: "1",
                                        on: {
                                            vdclose: {
                                                target: "pwoff peaoff vwclose veaclose vdclose",    }
                                        }
                                    },
                                    //"pwoff peaoff vwclosed veaclosed vdopened":
                                    "pwoff peaoff vwclose veaopen vdclose": {
                                        id: "2",
                                        on: {
                                            veaclose: { target: "pwoff peaoff vwclose veaclose vdclose" },
                                            peaon: {
                                                target: "pwoff peaon vwclose veaopen vdclose",
                                                cond: evaluateConditionPeaOn,
                                            },
                                            vwopen: { target: "pwoff peaoff vwopen veaopen vdclose" },
                                            pwon: { target: "pwoff peaoff vwopen veaopen vdclose" }
                                        }
                                    },
                                    //"pwoff peaoff vwclosed veaopened vdclosed"
                                    "pwoff peaon vwclose veaopen vdclose": {
                                        id: "3",
                                        on: {
                                            peaoff: { target: "pwoff peaoff vwclose veaopen vdclose" },
                                            vwopen: { target: "pwoff peaon vwopen veaopen vdclose" },
                                            pwon: { target: "pwoff peaon vwopen veaopen vdclose" }
                                        }
                                    },
                                    //"pwoff peaon vwclosed veaopened vdclosed"
                                    "pwoff peaoff vwopen veaclose vdclose": {
                                        id: "4",
                                        on: {
                                            vwclose: { target: "pwoff peaoff vwclose veaclose vdclose" },
                                            veaopen: { target: "pwoff peaoff vwclose veaopen vdclose" },
                                            peaon: { target: "pwoff peaoff vwopen veaopen vdclose" },
                                            pwon: {
                                                target: "pwon peaoff vwopen veaclose vdclose",
                                                cond: evaluateConditionPwOn}
                                        }
                                    },
                                    //"pwoff peaoff vwopened veaclosed vdclosed"
                                    "pwoff peaoff vwopen veaopen vdclose": {
                                        id: "6",
                                        on: {
                                            veaclose: { target: "pwoff peaoff vwopen veaclose vdclose" },
                                            vwclose: { target: "pwoff peaoff vwclose veaopen vdclose" },
                                            peaon: {
                                                target: "pwoff peaon vwopen veaopen vdclose",
                                                cond: evaluateConditionPeaOn,
                                            },
                                            pwon: {
                                                target: "pwon peaoff vwopen veaopen vdclose",
                                                cond: evaluateConditionPwOn,
                                            }
                                        }
                                    },
                                    // "pwoff peaoff vwopened veaopened vdclosed"
                                    "pwoff peaon vwopen veaopen vdclose": {
                                        id: "8",
                                        on: {
                                            peaoff: {
                                                target: "pwoff peaoff vwopen veaopen vdclose"
                                            },
                                            vwclose: { target: "pwoff peaon vwclose veaopen vdclose" }
                                        }
                                    },
                                    // "pwoff peaon vwopened veaopened vdclosed"
                                    "pwon peaoff vwopen veaclose vdclose": {
                                        id: "5",
                                        on: {
                                            pwoff: {
                                                target: "pwoff peaoff vwopen veaclose vdclose"
                                            },
                                            peaon: {
                                                target: "pwon peaoff vwopen veaopen vdclose"
                                            },
                                            veaopen: {
                                                target: "pwon peaoff vwopen veaopen vdclose"
                                            }
                                        }
                                    },
                                    // "pwon peaoff vwopened veaclosed vdclosed"
                                    "pwon peaoff vwopen veaopen vdclose": {
                                        id: "7",
                                        on: {
                                            veaclose: { target: "pwon peaoff vwopen veaclose vdclose" },
                                            pwoff: { target: "pwoff peaoff vwopen veaopen vdclose" }
                                        }
                                    }
                                },
                            },

                            "Stirrer": {
                                initial: stirrerstate,
                                states: {
                                    "Stirrer Off": {
                                        id: "0",
                                        on: {
                                            ston: {
                                                target: "Stirrer On"
                                            }
                                        }
                                    },
                                    "Stirrer On": {
                                        id: "1",
                                        on: {
                                            stoff: {
                                                target: "Stirrer Off"
                                            }
                                        }
                                    }
                                },
                            },
                            "Glass Reactor": {
                                initial: glass_reactorstate,
                                states: {
                                    "Empty": {
                                        id: "0",
                                        on: {
                                            rsomewater: {
                                                target: "Some Water"
                                            }
                                        }
                                    },
                                    "Unknown": {
                                        id: "6",
                                        on: {
                                            remptying: {
                                                target: "Emptying"
                                            }
                                        }
                                    },
                                    "Some Water": {
                                        id: "1",
                                        on: {
                                            remptying: {
                                                target: "Emptying"
                                            },
                                            renoughwater: {
                                                target: "Enough Water"
                                            }
                                        }
                                    },
                                    "Emptying": {
                                        id: "7",
                                        on: {
                                            unknown: {
                                                target: "Unknown"
                                            },
                                            rempty: {
                                                target: "Empty"
                                            }
                                        }
                                    },
                                    "Enough Water": {
                                        id: "2",
                                        on: {
                                            remptying: {
                                                target: "Emptying"
                                            },
                                            rea: {
                                                target: "Water > 2xEA"
                                            },
                                            rfull: {
                                                target: "Full",
                                            }
                                        }
                                    },
                                    "Water > 2xEA": {
                                        id: "3",
                                        on: {
                                            remptying: {
                                                target: "Emptying"
                                            },
                                            req: {
                                                target: "Water = 2xEA",
                                            },
                                            rful: {
                                                target: "Full",
                                            }
                                        }
                                    },
                                    "Water = 2xEA": {
                                        id: "4",
                                        on: {
                                            remptying: {
                                                target: "Emptying"
                                            },
                                            rgt: {
                                                target: "Water > 2xEA"
                                            },
                                            rfull: {
                                                target: "Full",
                                            }
                                        }
                                    },
                                    "Full": {
                                        id: "5",
                                        on: {
                                            remptying: {
                                                target: "Emptying"
                                            }
                                        }
                                    }
                                },
                            }
                        }
                    }
                }
            },
            "EA Tank": {
                id: "EA Tank",
                initial: eatankstate,
                states: {
                    "EA Empty": {
                        id: "0",
                        on: {
                            eafill: {
                                target: "EA Full"
                            }
                        }
                    },
                    "EA Full": {
                        id: "2",
                        on: {
                            eafill: {
                                target: "EA Full"
                            },
                            eaenough: {
                                target: "EA Enough"
                            }
                        }
                    },
                    "EA Enough": {
                        id: "1",
                        on: {
                            eaempty: {
                                target: "EA Empty",
                            },
                            eafill: {
                                target: "EA Full"
                            }
                        }
                    }
                }
            },
            "Light": {
                id: "Light",
                initial: lightstate,
                states: {
                    "Light Off": {
                        id: "0",
                        on: {
                            lon: {
                                target: "Light On"
                            }
                        }
                    },
                    "Light On": {
                        id: "1",
                        on: {
                            loff: {
                                target: "Light Off"
                            }
                        }
                    }
                }
            },
            "Value Changes": {
                id: "Value Changes",
                initial: "Value Update 1",
                states: {
                    "Value Update 1": {
                        id: "0",
                        on: {
                            valueupdate: {
                                target: "Value Update 2"
                            }
                        }
                    },
                    "Value Update 2": {
                        id: "1",
                        on: {
                            valueupdate: {
                                target: "Value Update 1"
                            }
                        }
                    }
                }
            }
        }, predictableActionArguments: true
    })
}