import "./Components.css"

export default function GateValve({ isOpen, onClick }) {
  return (
    <div style={{ width: "100%", height: "100%", position: "relative" }}>
    <svg
      version="1.1"
      width="27.669983"
      height="19.949997"
      viewBox="-0.5 -0.5 27.669983 19.949997"
      animation="{&quot;scriptArr&quot;:[],&quot;cellIdArr&quot;:[]}"
      isstartanimationwithpreviouspage="with"
      id="svg4997"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}>
      <g
        id="g4985"
        transform="translate(-531,-199.00005)">
        <g
          shape-node=""
          id="g4658">
          <path
            cell-id="dD5wv-8hnhxJDom6t97_-10"
            d="m 531,199 13.34,8 -13.34,8 z m 26.67,0 -13.33,8 13.33,8 z"
            fill={isOpen ? "green" : "#ffffff"}
            stroke="#000000"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            pointerEvents="all"
            id="path4656" />
        </g>
      </g>
      </svg>
      </div>
  )
}