import React, { useEffect, useState } from "react";
import translations from "../../../translations.json"
import "./../../AdminFunctionalities.css"
import { Input } from 'reactstrap';
import { getRequestWithToken, postRequestWithToken, putRequestWithToken } from "../../../http-requests/HTTPRequests.js";
import { Link } from "react-router-dom"

/**
 * This function returns the Institutions.
 * It is used to display the institutions, add facilities and change the institution details.
 */
export default function InstitutionsOverview() {

    const currentTranslations = translations[sessionStorage.getItem('language')] || {};

    const [institutions, setInstitutions] = useState([])
    const [externInstitutionName, setExternInstitutionName] = useState('');
    const [externPublicKey, setExternPublicKey] = useState('');
    const [externURL, setExternURL] = useState('');
    const [firstInstitution, setFirstInstitution] = useState([]) // Entferne die erste Institution aus dem Array und speichere sie in einer separaten Variable

    //Handles input changes
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        if (id === "extern_institution_name") {
            setExternInstitutionName(value)
        }
        if (id === "extern_public_key") {
            setExternPublicKey(value)
        }
        if (id === "extern_url") {
            setExternURL(value)
        }
    }

    //This method gets called when the component is used. It calls the getInstitutions method.
    useEffect(() => {
        getInstitutions();
    }, []);
    

    /**
     * This method gets the institutions. It sends a axios-request to the server. If the request
     * was successful it sets the institutions.
     */
    async function getInstitutions() {
        getRequestWithToken("", '/api/management/institutions', sessionStorage.getItem("token"))
            .then(response => {
                const institutionsToSet = response.data;
                setFirstInstitution(institutionsToSet[0]);
                setInstitutions(institutionsToSet.slice(1));
            }).catch(error => {
                console.log(error);
            })
    }

    //TODO: implement request to add extern facility

    async function addInstitution() {
        postRequestWithToken("", "/api/management/institutions/key/addInstitution", {institutionName: externInstitutionName, publicKey: externPublicKey, privateKey: "", institutionURL: externURL, registeredFacilitiesNames: [""], totalBookedTime: 0},
            sessionStorage.getItem("token")).then(
                (response) => {
                    console.log(response);
                    getInstitutions();
                }
        ).catch(error => {
            // document.getElementById("institutionInfo").innerHTML = currentTranslations.adminFuncAddFacilityFailed
            console.log(error)
        })
    }

    //Button name anpassen
    if (sessionStorage.getItem("user")) {
        return (
            <>
                {(sessionStorage.getItem('role') === 'ADMIN' || sessionStorage.getItem('role') === 'ROOT_ADMIN') &&
                    <div className="center"> 
                        <div className="institution-table">
                            <p className="info">{currentTranslations.ownInstitution}</p>
                            <table id="institution-table">
                                <thead>
                                    <tr className="settradmin">
                                        <th className="settabledataadmin">{currentTranslations.adminFuncInstitutions}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                        <tr key={firstInstitution.institutionId} className="settradmin">

                                        <td className="settabledataadmin">
                                            <Link to={`/changeInstitutionDetails/${firstInstitution.institutionId}`}>{firstInstitution.institutionName}</Link></td>
                                            </tr>
                                </tbody>
                            </table>
                            <div className="institution-table">
                                <p className="info">{currentTranslations.externInstitution}</p>
                                <table id="institution-table">
                                    <thead>
                                        <tr className="settradmin">
                                            <th className="settabledataadmin">{currentTranslations.adminFuncInstitutions}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {institutions.map(institution => (
                                            <tr key={institution.institutionId} className="settradmin">
                                                <td className="settabledataadmin">
                                                    <Link to={`/changeInstitutionDetails/${institution.institutionId}`}>{institution.institutionName}</Link></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        
                        <div className="add-extern-institution vertikal center">
                            <label className="add-extern-institution-form">Add Institution</label>
                            <input placeholder="institution name..." id="extern_institution_name" onChange={(e) => handleInputChange(e)} value={externInstitutionName} className="add-extern-institution-form"></input>
                            <input placeholder="institution public key..." id="extern_public_key" onChange={(e) => handleInputChange(e)} value={externPublicKey} className="add-extern-institution-form"></input>
                            <input placeholder="institution url..." id="extern_url" onChange={(e) => handleInputChange(e)} value={externURL} className="add-extern-institution-form"></input>
                            <button onClick={() => addInstitution()} className="add-extern-institution-form">Add</button>
                        </div>
                        <label id="institutionInfo"></label>

                    </div>}
            </>
        )
    }
    else {
        return (
            <div>{currentTranslations.loginRequired}</div>
        )
    }
}