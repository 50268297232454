import React, { useState } from 'react';
import translations from "./../../translations.json"
import "./Login.css"
import { postRequestWithCredentialsHeader, getRequestWithCredentialsHeader } from '../../http-requests/HTTPRequests.js';
import { Link } from 'react-router-dom';


//make it to axios
const Login = ({onLoginSuccess}) =>{

    const currentTranslations = translations[sessionStorage.getItem('language')] || {};

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    //Handles input changes.
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        if (id === "email_login") {
            setEmail(value);
        }
        if (id === "password_login") {
            setPassword(value);
        }
    }

    /**
     * This method handles the loging. It sends a axios-request to the server. 
     * If the request was successful, it sets the sessionStorage token, user, role and id, so it can be used for other functionalities.
     */
    async function login() {
        document.getElementById('login-label').innerHTML = currentTranslations.loggingIn
        postRequestWithCredentialsHeader("", "/api/management/users/authenticate", { username: email, password: password })
        .then(response => {
            if (response.data.status === "SUCCESS") {
                getRequestWithCredentialsHeader("", `/api/management/users/getuser/username?username=${email}`, { username: email, password: password }).then(
                        response2 => {
                            if(response2.status === 200){
                                sessionStorage.setItem("token", response.data.token);
                                sessionStorage.setItem("user", email)
                                sessionStorage.setItem("role", response2.data.role);
                                sessionStorage.setItem("userid", response2.data.userid)
                                sessionStorage.setItem("facilities", response2.data.registeredFacilitiesNames)
                                onLoginSuccess()
                            }
                        }
                    ).catch(error => {
                        console.log(error)
                        document.getElementById('login-label').innerHTML = currentTranslations.loginFailed
                    })
                
            } else {
                document.getElementById('login-label').innerHTML = currentTranslations.loginFailed
            }
        }).catch(error => {
            console.log(error);
            document.getElementById('login-label').innerHTML = currentTranslations.loginFailed
        })
    }

    if (!sessionStorage.getItem("user")) {
        return (
                <div className='center' data-testid="loginTest">
                <p className="info">Login</p>

                <div className="vertikal">
                    <div className="horizontal inputLogin">
                    <label className="labelFormLogin" htmlFor="username_login">{currentTranslations.enterEmail}</label>
<input type="text" placeholder={currentTranslations.placeholderEmail} id="email_login" data-testid="email-input" onChange={(e) => handleInputChange(e)} value={email} />

                    </div>
                    <div className="horizontal">
                        <label className="labelFormLogin" htmlFor="password">{currentTranslations.enterPassword}</label>
                        <input type="password" placeholder={currentTranslations.placeholderPassword} id="password_login" data-testid="password-input" onChange={(e) => handleInputChange(e)} value={password} />
                    </div>
                </div>
                <div className="loginfaq">
                    <p>
                        {currentTranslations.loginProblems} <Link to={"/faq"}>FAQ</Link>
                    </p>

                </div>
                <button onClick={login} className="submit-button" data-testid="login-button">Log in</button>
                <label id="login-label" data-testid="login-test-label"></label>
            </div>
        )
    } else {
        return (
            <div className='center'>
                <p>
                    {currentTranslations.loginSuccess}
                </p>
            </div>
        )
    }
}

export default Login