import React, { useState } from 'react';
import translations from "../../translations.json"
import "./AccountManagement.css"
import { putRequestWithToken } from '../../http-requests/HTTPRequests.js';

//Falls error: als const darstellen
export default function AccountManagement({changeAccountDetailsSuccess}) {

    const currentTranslations = translations[sessionStorage.getItem('language')] || {};


    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    //Handles input changes
    const handleInputChange = (e) => {
        const { id, value } = e.target;
        if (id === "password-input") {
            setPassword(value);
        }
        if (id === "confirm_password") {
            setConfirmPassword(value);
        }
    }

    async function changePassword(){
        if (password !== confirmPassword || password === null || confirmPassword === null) {
            document.getElementById("changePasswordLabel").innerHTML = currentTranslations.changePasswordNotEqual
            return;
        }
        document.getElementById("changePasswordLabel").innerHTML = currentTranslations.changingPassword
        const userid = sessionStorage.getItem("userid")
        putRequestWithToken("", `/api/management/users/updatepassword/userid?userid=${userid}`,
        {
            password: password, userid: userid
        }, sessionStorage.getItem("token")).then(response => {
            if(response.status === 200){
                changeAccountDetailsSuccess();
            }
        }).catch(
            error => {
                console.log(error)
            }
        );
    }

    //Button enablen wenn passwörter überinstimmen
    return (
        <>
            {sessionStorage.getItem("user") ?
                <div className="center" data-testid="accountManagementTest">
                    <div>
                        <p className="center info">
                            {currentTranslations.changeAccountDetailsLabel}
                        </p>
                        <div className="vertikal">
                            <div className="horizontal inputAccountManagement">
                                <label id="password" className="labelFormAccountManagement">{currentTranslations.enterNewPassword}</label>
                                <input type="password" placeholder={currentTranslations.placeholderPassword} id="password-input" data-testid="password-input" onChange={(e) => handleInputChange(e)} value={password}></input>

                            </div>
                            <div className="horizontal inputAccountManagement">
                                <label id="confirmPassword" className="labelFormAccountManagement">{currentTranslations.confirmNewPassword}</label>
                                <input type="password" placeholder={currentTranslations.placeholderConfirmPassword} id="confirm_password" data-testid="confirmPassword-input" onChange={(e) => handleInputChange(e)} value={confirmPassword}></input>

                            </div>

                        </div>
                        <button onClick={() => changePassword()} data-testid="change-button">{currentTranslations.buttonChangePassword}</button>
                        <div>
                            <label id="changePasswordLabel" data-testid="changePasswordLabelTest"></label>
                        </div>
                    </div>
                </div> : <>
                </>}
        </>
    )
}

