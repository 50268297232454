import { Link } from "react-router-dom"
import translations from "./../../translations.json"
import React from 'react'
import "./Footer.css"


export default function Footer() {
    const currentTranslations = translations[sessionStorage.getItem('language')] || {};
    

    function CustomLink({ to, children, ...props }) {
        return (
            <li className="footer-item">
                <Link to={to} {...props}className="footer-link" >{children}</Link>
            </li>
        )
    }

    return (<nav className="footer">

        <ul>
            <CustomLink to="/dataprotection">{currentTranslations.dataprotection}</CustomLink>
            <CustomLink to="/faq">FAQ</CustomLink>
        </ul>
    </nav>
    )

}