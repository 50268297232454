import React from "react";
import { useState } from "react";
import translations from "./../../translations.json"
import "./Registration.css"
import { postRequestWithoutAuth } from "../../http-requests/HTTPRequests.js";
import { Link } from "react-router-dom";

/**
 * This function returns the Registration.
 * It is used to display the registration form.
 */
function Registration() {
    
    const currentTranslations = translations[sessionStorage.getItem("language")] || {};
    
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [firstname, setFirstName] = useState('')
    const [lastname, setLastName] = useState('')
    //const role = "ADMIN"
    const role = "APPLICANT"
    const resetpasswordstatus = "null"
    const loginStatus = sessionStorage.getItem("user")

    //Handles input changes
    const handleInputChange = (e) => {
        const {id, value} = e.target;
        if(id === "email_registration"){
            setEmail(value);
        }
        if(id === "password_registration"){
            setPassword(value);
        }
        if(id === "firstname_registration"){
            setFirstName(value)
        }
        if(id === "lastname_registration"){
            setLastName(value)
        }
    }

    //Checks if input is email.
    const isValidEmail = (email) => {
        const emailRegex = /\S+@\S+\.\S+/;
        return emailRegex.test(email);
    };

    async function register() {
        //DSGVO check
        if (!document.getElementById('dsgvoCheckbox').checked) {
            document.getElementById('registration').innerHTML = currentTranslations.acceptDataProtection
            return;
        }
        //E-Mail check
        if (!isValidEmail(email)) {
            document.getElementById('registration').innerHTML = currentTranslations.noValidEmail
            return;
        }
        document.getElementById('registration').innerHTML = currentTranslations.registering
        //Send HTML-Request
        postRequestWithoutAuth("", '/api/management/users/register', 
        { username: email, firstname: firstname, lastname: lastname, password: password, role: role, registeredFacilities: [""], email: email })
            .then((response) => {
                if (response.status === 200) {
                    document.getElementById('registration').innerHTML = currentTranslations.registrationSucces
                }
            }).catch(error => {
            document.getElementById('registration').innerHTML = currentTranslations.registrationFailed;
            console.log(error);
        })
    }

    //Einfügen, dasss registrierung erfolgreich war
    if (!loginStatus) {
        return (
            <div className='center' data-testid="registrationTest">
                <p className="info">{currentTranslations.registration}</p>
                <div className="vertikal">
                    <div className="horizontal inputRegister">
                        <label className="labelFormRegister">{currentTranslations.enterEmail}</label>
                        <input type="text" placeholder={currentTranslations.placeholderEmail} id="email_registration" data-testid="email-input" onChange={(e) => handleInputChange(e)} value={email} />
                    </div>
                    <div className="horizontal inputRegister">
                        <label className="labelFormRegister">{currentTranslations.enterFirstname}</label>
                        <input type="text" placeholder={currentTranslations.placeholderFirstname} id="firstname_registration" data-testid="firstname-input" onChange={(e) => handleInputChange(e)} value={firstname}/>
                    </div>
                    <div className="horizontal inputRegister">
                        <label className="labelFormRegister">{currentTranslations.enterLastname}</label>
                        <input type="text" placeholder={currentTranslations.placeholderLastname} id="lastname_registration" data-testid="lastname-input" onChange={(e) => handleInputChange(e)} value={lastname} />
                    </div>
                    <div className="horizontal inputRegister">
                        <label className="labelFormRegister">{currentTranslations.enterPassword}</label>
                        <input type="password" placeholder={currentTranslations.placeholderPassword} id="password_registration" data-testid="password-input" onChange={(e) => handleInputChange(e)} value={password} />
                    </div>
                </div>
                <div className="registerdsgvo">
                    <label htmlFor="dsgvoCheckbox">
                        <input type="checkbox" id="dsgvoCheckbox" required />
                        {currentTranslations.acceptingDataProtection} <Link to="\dataprotection"> DSGVO</Link>
                    </label>
                </div>
                <div className="registerfaq">
                    <p>
                        {currentTranslations.registerProblems} <Link to={"/faq"}>FAQ</Link>
                    </p>

                </div>
                <button onClick={register} className="submit-button" data-testid="Register Button">{currentTranslations.buttonRegister}</button>
                <label id='registration' data-testid="registration-test-label"></label>  
            </div>
        )
    }
    else {
        return(
        <div>
            {currentTranslations.alreadyLoggedIn}
        </div>
        )
    }
}

export default Registration